export const NOT_FOUND = "NOT_FOUND";

export const DARK_CONSTRAST_COLOR = '#333';
export const LIGHT_CONSTRAST_COLOR = '#ffffff';
export const TRACK_CONSTRAST_COLOR = "data-contrast-color";
export const DARK = "DARK";
export const LIGHT = "LIGHT";
export const ADD_ONS = {
	DROP_OFF_POINT: 'drop-off-point'
}
