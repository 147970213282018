import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceUnavailableComponent } from './components/service-unavailable/service-unavailable.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeadersInterceptor } from './services/interceptors/http-headers.interceptor';
import { ImgFallbackDirective } from './directives/img-fallback.directive';
import { PreviewComponent } from './components/preview/preview.component';
import { BroadcastMessageComponent } from './components/broadcast-message/broadcast-message.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { StoreDriverInfoComponent } from './components/store-driver-info/store-driver-info.component';
import { SiteFooterComponent } from './components/site-footer/site-footer.component';
import { FeedbackBoxComponent } from './components/feedback-box/feedback-box.component';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { SingleAdsBannerComponent } from './components/single-ads-banner/single-ads-banner.component';
import { OrderUnavailableComponent } from './components/order-unavailable/order-unavailable.component';
import { ImagesModalComponent } from './components/images-modal/images-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComplianceSectionComponent } from './components/compliance-info/compliance-info.component';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { CaptureEventDirective } from "./app.directive";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

export function HttpLoaderFactory(http: HttpClient) {
	// cache busting value = year-month-date-number - increment number if multiple changes on same date
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json?version=2024-07-05");
}

@NgModule({
	declarations: [
		AppComponent,
		ServiceUnavailableComponent,
		PageNotFoundComponent,
		HomeComponent,
		ImgFallbackDirective,
		PreviewComponent,
		BroadcastMessageComponent,
		GoogleMapComponent,
		StoreDriverInfoComponent,
		SiteFooterComponent,
		FeedbackBoxComponent,
		SingleAdsBannerComponent,
		OrderUnavailableComponent,
		ImagesModalComponent,
		ComplianceSectionComponent,
		CookieConsentComponent,
		CaptureEventDirective,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		NgbModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	providers: [HttpClientModule, { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true }],
	bootstrap: [AppComponent],
})
export class AppModule {}
