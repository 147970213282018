import { Component, Input, OnInit } from '@angular/core';
import { FeedbackService } from 'src/app/services/feedback.service';

export interface FeedBackForm {
  rating: null | number;
  comment: string;
}

@Component({
  selector: 'ds-feedback-box',
  templateUrl: './feedback-box.component.html',
  styleUrls: ['./feedback-box.component.scss']
})
export class FeedbackBoxComponent implements OnInit {
  @Input() orderId!: string;
  @Input() feedback: { rating: number, comment: string } | undefined = undefined; // to restore state in case order has feedback shared before
  public isFeedbackShared = false;

  public formObject: { rating: string | null, comment: string } = {
    rating: null,
    comment: ""
  };

  constructor(private feedbackService: FeedbackService) { }

  ngOnInit(): void {
    if (this.feedback) {
      this.formObject = {
        rating: this.feedback.rating + "",
        comment: this.feedback.comment
      }
    }

    this.isFeedbackShared = !!this.feedback?.rating;
  }

  async onSubmit() {
    // rating field is mandatory
    if (!this.formObject.rating) {
      return;
    }

    this.isFeedbackShared = true;

    const body = {
      rating: +this.formObject.rating,
      comment: this.formObject.comment,
      source: "live-tracking"
    };

    this.feedbackService.sendFeedback(body, this.orderId)
      .subscribe({ error: () => { this.isFeedbackShared = false } });
  }
}
