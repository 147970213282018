<div class="footer-container d-flex flex-column flex-md-row-reverse align-items-center align-items-md-stretch py-3 justify-content-between">
  <div class="w-50 text-center text-md-end mb-3 mb-md-0">
    <a href="https://deliverysolutions.co/privacy" class="secondary-data f-14px policy-text"
			target="_blank" rel="noreferrer noopener" [captureEvent]="'click-ds-privacy-notice'">{{ 'GENERIC.COMMON_PAGES.PRIVACY_LINK_TEXT' | translate }}</a>
  </div>
  <div class="w-50 footer-text-container my-md-0">
    <div
      class="powered-by d-flex flex-column justify-content-start text-center width-fit-content mx-auto mx-md-0"
      [captureEvent]="'click-powered-by-ds'"
    >
      <a
        href="https://www.deliverysolutions.co/"
        target="_blank"
				class="position-relative">{{ 'GENERIC.COMMON_PAGES.POWERED_BY_TEXT' | translate }}</a>

      <!--Business Logo start-->
      <a href="https://www.deliverysolutions.co/" target="_blank" rel="noopener noreferrer">
        <img src="../../../assets/icons/deliverysolutions.svg">
      </a>
      <!--Business Logo end-->
    </div>
  </div>
</div>
