import { Component, Input, OnInit } from '@angular/core';
import { LiveTrackingConfigInterface } from 'src/app/ts-interfaces/live-tracking-config.interface';
import { OrderInterface } from 'src/app/ts-interfaces/order.interface';
import { listOfLinesID, listOfLinesKey } from 'src/app/shared/constants/compliance-info';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: "app-compliance-info-box-section",
	templateUrl: "./compliance-info.component.html",
	styleUrls: ["./compliance-info.component.scss"],
})
export class ComplianceSectionComponent implements OnInit {
	@Input() liveTrackingConfig!: LiveTrackingConfigInterface;
	@Input() order!: OrderInterface;

	complianceList: any;
	count: number = 0;
	complianceInfo: string = "";
	alcoholImg: boolean = false;
	tobaccoImg: boolean = false;
	rxImg: boolean = false;
	multipleComplianceImg: boolean = false;
	complianceListText: Array<string> = [];
	showComplianceMessage: boolean = false;
	checkComplianceConfig: boolean = false;
	imgSrc: string = "";

	private containsAlcoholText = this.translate.instant('GENERIC.COMMON_PAGES.CONTAINS_ALCOHOL');
	private containsTobaccoText = this.translate.instant('GENERIC.COMMON_PAGES.CONTAINS_TOBACCO');
	private containsRestrictedText = this.translate.instant('GENERIC.COMMON_PAGES.CONTAINS_RESTRICTED');
	private prescriptionText = this.translate.instant('GENERIC.COMMON_PAGES.PRESCRIPTION_TEXT');

	constructor(private translate: TranslateService) {}

	ngOnInit() {
		this.checkComplianceConfig = this.liveTrackingConfig?.componentVisibility?.showComplianceInfo?.active;
		this.setComplianceInfo();
	}

	setComplianceInfo() {
		if (this.checkComplianceConfig) {
			this.complianceListText = [];
			this.complianceList = [];
			this.complianceList.push(this.order.isSpirit === true || this.order.isBeerOrWine === true);
			this.complianceList.push(this.order.isTobacco === true);
			this.complianceList.push(this.order.isRx === true);
			this.count = this.complianceList.filter((compliancedetails: boolean) => compliancedetails === true).length;
			this.complianceInfo = "";
			if (this.count === 1) {
				this.complianceListText = this.complianceList.map((compliancedetails: boolean, index: number) =>
					compliancedetails === true 
						? this.translate.instant(listOfLinesKey[index]) + '. ' + this.translate.instant(listOfLinesID[index])
						: false,
				);
				this.complianceListText = this.complianceListText.filter((x) => x);
				this.complianceInfo = this.complianceListText[0];
			} else if (this.count > 1) {
				this.complianceListText = this.complianceList.map((complianceList: boolean, index: number) =>
					complianceList === true
						? this.translate.instant(listOfLinesKey[index]) + '. ' + this.translate.instant(listOfLinesID[index])
						: false,
				);
				this.complianceListText = this.complianceListText.filter((x) => x);

				if (this.complianceListText.includes(this.prescriptionText)) {
					this.complianceInfo =
						this.translate.instant("GENERIC.COMMON_PAGES.CONTAINS_RESTRICTED") + "- " +
						this.complianceListText.join(", ") +
						". " + this.translate.instant("GENERIC.COMMON_PAGES.REQUIRED_ID_PRESCRIPTION");
				} else {
					this.complianceInfo =
						this.translate.instant("GENERIC.COMMON_PAGES.CONTAINS_RESTRICTED") + "- " +
						this.complianceListText.join(", ") +
						". " + this.translate.instant("GENERIC.COMMON_PAGES.REQUIRED_ID");
				}
			}
			if (this.complianceInfo) {
				this.showComplianceMessage = true;
			} else {
				this.showComplianceMessage = false;
			}
			this.showImagesBasedOnComplianceInfo(this.complianceInfo);
		}
	}

	public showImagesBasedOnComplianceInfo(complianceInfo: string) {
		if (complianceInfo.includes(this.containsAlcoholText)) {
			this.alcoholImg = true;
			this.imgSrc = "assets/compliance/alcohol.svg";
		} else if (complianceInfo.includes(this.containsTobaccoText)) {
			this.tobaccoImg = true;
			this.imgSrc = "assets/compliance/tobacco.svg";
		} else if (complianceInfo.includes(this.containsRestrictedText)) {
			this.multipleComplianceImg = true;
			this.imgSrc = "assets/compliance/multiple-items.svg";
		} else if (complianceInfo.includes(this.prescriptionText)) {
			this.rxImg = true;
			this.imgSrc = "assets/compliance/rx.svg";
		}
	}
}
