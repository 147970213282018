import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClevertapAnalyticsService } from '../../services/clevertap-analytics.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { OrderService } from '../../services/order.service';
import { TrackerService } from '../../services/tracker.service';
import { ORDER_CANCELLED_CATEGORY, ORDER_DELIVERED_CATEGORY, ORDER_DISPATCHED, ORDER_DISPATCHED_CATEGORY, OUT_FOR_DELIVERY, OUT_FOR_DELIVERY_CATEGORY, ORDER_FAILED_CATEGORY, SystemStatus, ORDER_EXCEPTION, ORDER_UNASSIGNED, ORDER_DELAYED, ORDER_AT_LOCATION, STAGED_CATEGORY } from '../../shared/constants/order-statuses';
import { contrastColorOf } from '../../shared/functions/generate-contrast-color';
import { getDSPLogoURL } from '../../shared/functions/get-dsp-logo-url';
import { generateDatetimeToDisplayOnOrderProgressbar, generateHumanReadableDatetimeFormat, getTime, getDate } from '../../shared/utils/date-utils';
import { AdConfig, BusinessLogo, HeaderComponent, LiveTrackingBrandingInterface, LiveTrackingConfigInterface } from '../../ts-interfaces/live-tracking-config.interface';
import { ItemList, OrderInterface } from '../../ts-interfaces/order.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { ADD_ONS } from '../../shared/constants/generic-constants';
import { MD5 } from "crypto-js";
import { Userpilot } from "userpilot";
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'ds-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	public liveTrackingConfig: LiveTrackingConfigInterface | undefined;
	public liveTrackingBranding: LiveTrackingBrandingInterface | undefined;
	public secondaryAdsOne: AdConfig | undefined;
	public secondaryAdsTwo: AdConfig | undefined;
	public primaryAds: AdConfig | undefined;
	public order!: OrderInterface;
	private streamSource: any;
	private destroy$ = new Subject();
	getDSPLogoURL = getDSPLogoURL;
	public isClientToken = false;
	businessLogo: BusinessLogo | undefined;
	header: HeaderComponent | undefined;
	public isHeaderBackgroundWhite: boolean = false;
	public customerInitials: string = "";
	public customerAddress: string = "";
	desktopDeliveryInstructions: string = "";
	deliveryInstructions: string = "";
	readMoreOrLess = false;
	orderValueVisibility = false;
	orderItemsVisibility = false;
	packageItemList: ItemList[] = [];
	orderAttributeList: Array<{ key: string; value: string }> = [];
	public providerName: string | undefined = '';
	public isPendingDispatch = false;
	public isOrderDelivered = false;
	public isOutForDelivery = false;
	public isOrderCancelled = false;
	public isOrderExceptioned = false;
	public isOrderDispatched = false;
	public isOrderFailed = false;
	public isOrderUnAssigned = false;
	public isOrderDelayed = false;
	public estimatedDeliveryTime = "";
	public orderConfirmedTime = "";
	public orderPickedTime = "";
	public orderDeliveredAt = "";
	public orderCancelledOn = "";
	public orderFailedOn = "";
	public orderExceptionedOn = "";
	public arrivingInTime = "";
	public orderCancelledBeforeConfirmation = false;
	public orderCancelledBeforePickup = false;
	public orderCancelledBeforeDelivery = false;
	public isOrderOnTime = false;
	public orderHiddenFields: { [key: string]: boolean } = {};
	public desktopMode = false;
	public images: string[] = [];
	public isOrderAtLocation = false;
	public orderAtLocationOn = "";
	public isOrderStaged = false;
	public needToShowOrderAtLocationStatus = false;
	public onTimeDelayedIndicatorVisibility = true;
	public scheduledDropoffTimeVisibility = false;
	public scheduledDropOffTimeString = "";
	public orderStatusText: { text: string, time: string } = { text: '', time: '' };

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private trackerService: TrackerService,
		private orderService: OrderService,
		private gaAnalyticsService: GoogleAnalyticsService,
		private ctAnalyticsService: ClevertapAnalyticsService,
		private modalService: NgbModal,
		private translate: TranslateService,
	) { }

	ngOnInit(): void {
		this.gaAnalyticsService.setupDSGoogleAnalytics();

		this.activatedRoute.data.subscribe(async (data) => {
			if (
				!data || !data.data || !data.data.liveTrackingConfig
			) {
				this.router.navigate(['/service-unavailable'], { skipLocationChange: true });
				return;
			}

			data = data.data;

			this.liveTrackingConfig = (data['liveTrackingConfig'] as LiveTrackingConfigInterface);
			this.liveTrackingBranding = (data['liveTrackingBranding'] as LiveTrackingBrandingInterface);
			this.order = data['order'];

			if (this.liveTrackingBranding){
				this.setupTheme(this.liveTrackingBranding);
				this.setFontVariables(this.liveTrackingBranding);
				this.setPageTitleAndFavicon(this.liveTrackingBranding);
				this.setUpAdsConfig(this.liveTrackingBranding);
			}


			if (this.liveTrackingConfig) {
		    this.setupGoogleAnalytics(this.liveTrackingConfig);
				this.setupCleverTapAnalytics(this.liveTrackingConfig);
				this.setupUserPilotIdentifier();
				this.setOrderAttributeList(this.order, this.liveTrackingConfig);

				this.orderValueVisibility = !!this.order && this.liveTrackingConfig?.componentVisibility?.orderDetails.orderValue.active;
				this.orderItemsVisibility = !!this.order && this.liveTrackingConfig?.componentVisibility?.orderDetails.orderItems.active;
				this.scheduledDropoffTimeVisibility = !!this.order && this.liveTrackingConfig?.componentVisibility?.scheduledDropoffTime?.active;
				this.onTimeDelayedIndicatorVisibility = !!this.order && this.liveTrackingConfig?.componentVisibility?.onTimeDelayedIndicator?.active;
				this.orderHiddenFields = this.orderService.setHiddenFields(this.liveTrackingConfig);
			}

			if (this.order?.redirectUrl) {
				window.open(this.order.redirectUrl, '_self');
			}

			if (this.order) {
				this.updateOrderVariables(this.order);
				this.setupStreamingService();
				const [firstName, secondName] = this.order.deliveryContact.name.split(' ');
				this.customerInitials = firstName.charAt(0).toUpperCase();
				if (secondName) {
					this.customerInitials += ` ${secondName.charAt(0).toUpperCase()}`;
				}
				if (this.order.deliveryAddress) {
					this.customerAddress = this.order.deliveryAddress.street;
					if (this.order.deliveryAddress.street2) {
						this.customerAddress += ", ";
						this.customerAddress += this.order.deliveryAddress.street2;
					}
					this.customerAddress = `${this.customerAddress}, ${this.order.deliveryAddress.city}, ${this.order.deliveryAddress.state} ${this.order.deliveryAddress.zipcode}, ${this.order.deliveryAddress.country}`;
				}

				if (this.order.deliveryInstructions) {
					this.deliveryInstructions = this.order.deliveryInstructions;
					this.desktopDeliveryInstructions = this.order.deliveryInstructions.length > 134 ? this.order.deliveryInstructions.slice(0, 134) : this.order.deliveryInstructions;
				}

				this.packageItemList = this.order.itemList || (this.order.packages && this.order.packages[0]?.itemList) || [];

				if (this.order && this.order.attachments && NullEmptyChecker.isNonEmptyArray(this.order.attachments)) {
					this.images = this.order.attachments.map(attachment => attachment.url) || [];
				}

				if (this.order.provider || this.order.preferredProvider) {
					this.providerName = (this.order.carrier?.providerInfo.name === 'Self Delivery' ? this.order.provider :
						this.order.carrier?.providerInfo.name) || this.order.provider || this.order.preferredProvider;
				}
			}

			this.businessLogo = this.liveTrackingBranding.branding.businessLogos.find(logo => logo.size === "large");
			this.header = this.liveTrackingBranding.components.header;
		});
	}
	setupUserPilotIdentifier() {
		const tenantId = this.order?.tenantId;
		const brandName = this.liveTrackingConfig?.brandName;
		const userId = MD5(this.order?.deliveryContact.phone!).toString();
		Userpilot.identify(userId, {
			tenantId,
			company: {
				id: tenantId,
				module: `live-tracking`,
				brand: brandName,
			},
		});
	}
	// setup page theme. these css variables will be used cross page for proper brand experience
	private setupTheme(liveTrackingConfig: LiveTrackingBrandingInterface) {
		if (liveTrackingConfig.branding.color.primary) {
			const contrastColor = contrastColorOf(liveTrackingConfig.branding.color.primary);

			// primary color for site
			document.documentElement.style
				.setProperty('--primary', liveTrackingConfig.branding.color.primary);
			// when you use primary as background use this as font color
			document.documentElement.style
				.setProperty('--primary-contrast', contrastColor); // contrasting black or white
		}

		if (liveTrackingConfig.branding.color.background) {
			document.documentElement.style
				.setProperty('--header-bg', liveTrackingConfig.branding.color.background);

			const headerBg = liveTrackingConfig.branding.color.background;

			this.isHeaderBackgroundWhite =
				headerBg === '#fff' || headerBg === '#ffffff' || headerBg === '#FFFFFF' || headerBg === 'white';
		}
	}

	// use fonts provider by brand for page
	private setFontVariables(liveTrackingConfig: LiveTrackingBrandingInterface) {
		const { branding } = liveTrackingConfig;

		let content = "", heading = "";

		if (branding.fonts.content) {
			const contentFontType = branding.fonts.content.split('.').pop();

			content = `@font-face {
        font-family: 'content_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${branding.fonts.content}) format('${contentFontType}');
      }`;
		}

		if (branding.fonts.heading) {
			const headingFontType = branding.fonts.heading.split('.').pop();

			heading = `@font-face {
        font-family: 'heading_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${branding.fonts.heading}) format('${headingFontType}');
      }`;
		}

		const style = document.createElement('style');
		style.innerHTML = `
      ${content}
      ${heading}
    `;

		document.head.appendChild(style);
	}

	private setupStreamingService() {
		let { token, trackingNumber, provider } = this.activatedRoute.snapshot.queryParams;
		let tenantId = this.order?.tenantId;
		if (!token) {
			token = this.activatedRoute.snapshot.queryParams['ct'];
			this.isClientToken = token ? true : false;
		}

		// sometimes order isn't present,
		// in that case do nothing...
		// token is required for streaming service.
		if (!this.order || (!token && !(trackingNumber && provider))) {
			return;
		}

		this.streamSource = this.trackerService.subscribeTrackerInfo(
			this.order._id,
			token,
			this.isClientToken,
			tenantId,
			trackingNumber,
			provider,
			(order) => {
				// on first call streaming returns empty object {}
				if (!order || !Object.keys(order)?.length) {
					return;
				}

				this.order = Object.assign({}, this.order, order);
				if (this.order) {
					this.fetchOrderStatusHistory(this.order)
						.pipe(takeUntil(this.destroy$)).subscribe(result => {
							if (this.order) {
								if (result.orderStatus) {
									this.order.statusHistory = result.orderStatus;
								}
								if (result.route) {
									this.order.route = result.route;
								}
								if (!this.liveTrackingConfig?.componentVisibility?.proofOfDelivery?.active && this.order && Array.isArray(this.order?.attachments) && this.order.attachments.length > 0) {
									delete this.order.attachments;
								}
								this.order && this.updateOrderVariables(this.order);
							}
						});
				}
			},
			(error) => { console.log('streaming service error: ', error) }
		);
	}

	private setUpAdsConfig(liveTrackingConfig: LiveTrackingBrandingInterface) {
		this.secondaryAdsOne = liveTrackingConfig.components.ads.find(ads => {
			return ads.type === "secondaryOne";
		});

		this.secondaryAdsTwo = liveTrackingConfig.components.ads.find(ads => {
			return ads.type === "secondaryTwo";
		});

		this.primaryAds = liveTrackingConfig.components.ads.find(ads => {
			return ads.type === "primary";
		});
	}

	private setPageTitleAndFavicon(liveTrackingConfig: LiveTrackingBrandingInterface) {
		const { branding } = liveTrackingConfig;

		if (
			branding.favicon.active
			&& branding.favicon.pageTitle
		) {
			document.title = branding.favicon.pageTitle;
		} else if (this.order) {
			document.title = `Order #${this.order.orderExternalId} | ${this.order.brandName}`;
		} else if (liveTrackingConfig) {
			document.title = liveTrackingConfig.brandName;
		}

		if (
			branding.favicon.active && branding.favicon.url
		) {
			const link = document.createElement('link');
			link.rel = 'icon';
			link.type = 'image/png';
			link.href = branding.favicon.url;
			document.head.appendChild(link);
		}
	}

	private setupGoogleAnalytics(liveTrackingConfig: LiveTrackingConfigInterface) {
		this.gaAnalyticsService.setupGoogleAnalytics(liveTrackingConfig);
	}

	private setupCleverTapAnalytics(liveTrackingConfig: LiveTrackingConfigInterface) {
		this.ctAnalyticsService.setupClevertapAnalytics(liveTrackingConfig);
	}

	private fetchOrderStatusHistory(order: OrderInterface): Observable<any | undefined | string> {
		return this.orderService.getOrderStatus(order._id.toString());
	}

	/**
	 * Computes the order status based on the given order object.
	 * @param order - The order object containing various properties such as status, delivery time, and delivery address.
	 * @returns An object with the text and time values representing the order status.
	*/
	private computeOrderStatus(order: OrderInterface) {
		let requestedDeliveryTime;
		if (!order.isDropoffASAP && order.dropoffTime?.endsAt) {
			requestedDeliveryTime = generateDatetimeToDisplayOnOrderProgressbar(order.dropoffTime?.endsAt, order.timeZone);
		} else {
			requestedDeliveryTime = getDate(new Date(order.createdAt).getTime(), order.timeZone);
		}
		let orderStatus: { text: string, time: string };
		if (this.isPendingDispatch) {
			orderStatus = {
				text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_PENDING_DISPATCHED'),
				time: ''
			};
			return orderStatus;
		} else if (this.isOrderDispatched) {
			// if etd is present then show etd else show requestedDeliveryTime
			if (this.estimatedDeliveryTime) {
				orderStatus = {
					text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_DISPATCHED_ETA'),
					time: `${this.estimatedDeliveryTime}`
				};
				return orderStatus;
			} else {
				this.scheduledDropoffTimeVisibility = false;
				orderStatus = {
					text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_DISPATCHED'),
					time: `${requestedDeliveryTime}`
				}
				return orderStatus;
			}
		} else if (this.isOutForDelivery) {
			// if etd is present then show etd else show requestedDeliveryTime
			if (this.estimatedDeliveryTime) {
				orderStatus = {
					text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_OUT_FOR_DELIVERY_ETA'),
					time: `${this.arrivingInTime}`
				};
				return orderStatus;
			} else {
				this.scheduledDropoffTimeVisibility = false;
				orderStatus = {
					text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_OUT_FOR_DELIVERY'),
					time: `${requestedDeliveryTime}`
				}
				return orderStatus;
			}
		} else if (this.isOrderAtLocation && this.needToShowOrderAtLocationStatus) {
			orderStatus = {
				text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_AT_LOCATION'),
				time: `${this.orderAtLocationOn}`
			};
			return orderStatus;
		} else if (this.isOrderDelivered) {
			orderStatus = {
				text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_DELIVERED'),
				time: `${this.orderDeliveredAt}`
			};
			return orderStatus;
		} else if (this.isOrderCancelled) {
			orderStatus = {
				text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_CANCELLED'),
				time: `${this.orderCancelledOn}`
			}
			return orderStatus;
		} else if (this.isOrderFailed) {
			orderStatus = {
				text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_FAILED'),
				time: `${this.orderFailedOn}`
			}
			return orderStatus;
		} else if (this.isOrderExceptioned) {
			orderStatus = {
				text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_EXCEPTION'),
				time: `${this.orderExceptionedOn}`
			}
			return orderStatus;
		} else {
			orderStatus = {
				text: '',
				time: ''
			};
			return orderStatus;
		}
	}

	/**
	 * Sets up the visibility for the on-time and delayed indicators based on the given order object.
	 * @param order - The order object containing the necessary information.
	*/
	private setupOntimeDelayedIndicatorVisibility(order: OrderInterface) {
		let indexOfDelivery = order.statusHistory.findIndex(statusDetails => ORDER_DELIVERED_CATEGORY.includes(statusDetails.status));
		const deliveredStatus = order.statusHistory[indexOfDelivery];
		const isDropoffASAP = order.isDropoffASAP;
		if (this.onTimeDelayedIndicatorVisibility) {
			const requestedDeliveryTime = order.dropoffTime?.endsAt;
			if (requestedDeliveryTime && !isDropoffASAP) {
				if (this.isOutForDelivery) {
					this.isOrderOnTime = new Date().getTime() < requestedDeliveryTime;
				} else if (this.isOrderDelivered || this.isOrderAtLocation) {
					if (order?.actualDeliveryTime) {
						this.isOrderOnTime = order.actualDeliveryTime < requestedDeliveryTime;
					} else if (deliveredStatus) {
						const deliveredAt = deliveredStatus.updatedAtEpoch;
						this.isOrderOnTime = deliveredAt < requestedDeliveryTime;
					} else {
						this.onTimeDelayedIndicatorVisibility = false;
					}
				} else {
					this.onTimeDelayedIndicatorVisibility = false;
				}
			} else {
				this.onTimeDelayedIndicatorVisibility = false;
			}
		}
	}

	/**
	 * Sets up the visibility for the drop-off time based on the given order object.
	 * @param order - The order object containing the necessary information.
	*/
	private setupDropoffTimeVisibility(order: OrderInterface) {
		const isDropoffASAP = order.isDropoffASAP;
		if (this.scheduledDropoffTimeVisibility && !isDropoffASAP) {
			const scheduledDropoffTimeStartsAt = order.dropoffTime?.startsAt;
			const scheduledDropoffTimeEndsAt = order.dropoffTime?.endsAt;
			this.scheduledDropOffTimeString = this.isOutForDelivery 
				? this.translate.instant('GENERIC.PLATFORM.SCHEDULED_ARRIVAL')
				: this.translate.instant('GENERIC.PLATFORM.SCHEDULED_DELIVERY');
			if (scheduledDropoffTimeStartsAt && scheduledDropoffTimeEndsAt) {
				const scheduledDropoffStartDate = getDate(scheduledDropoffTimeStartsAt, order.timeZone);
				const scheduledDropoffEndDate = getDate(scheduledDropoffTimeEndsAt, order.timeZone);
				const isOnSameDate = scheduledDropoffStartDate === scheduledDropoffEndDate;
				if (isOnSameDate) {
					const scheduledDropOffTimeStartsAt = getTime(scheduledDropoffTimeStartsAt, order.timeZone);
					const scheduledDropOffTimeEndsAt = getTime(scheduledDropoffTimeEndsAt, order.timeZone);
					this.scheduledDropOffTimeString = `${this.scheduledDropOffTimeString} ${this.translate.instant('GENERIC.PLATFORM.ON_TEXT')} ${scheduledDropoffStartDate} ${scheduledDropOffTimeStartsAt} ${this.translate.instant('GENERIC.PLATFORM.TO_TEXT').toLowerCase()} ${scheduledDropOffTimeEndsAt}`;
				} else {
					const scheduledDropoffDateStartsAt = generateDatetimeToDisplayOnOrderProgressbar(scheduledDropoffTimeStartsAt, order.timeZone);
					const scheduledDropoffDateEndsAt = generateDatetimeToDisplayOnOrderProgressbar(scheduledDropoffTimeEndsAt, order.timeZone);
					this.scheduledDropOffTimeString = `${this.scheduledDropOffTimeString} ${this.translate.instant('GENERIC.PLATFORM.BETWEEN_TEXT')} ${scheduledDropoffDateStartsAt} ${this.translate.instant('GENERIC.PLATFORM.AND_TEXT').toLowerCase()} ${scheduledDropoffDateEndsAt}`;
				}
			} else if (scheduledDropoffTimeEndsAt) {
				const scheduledDropoffDateEndsAt = generateDatetimeToDisplayOnOrderProgressbar(scheduledDropoffTimeEndsAt, order.timeZone);
				this.scheduledDropOffTimeString = `${this.scheduledDropOffTimeString} ${this.translate.instant('GENERIC.PLATFORM.BY_TEXT')} ${scheduledDropoffDateEndsAt}`;
			} else {
				this.scheduledDropoffTimeVisibility = false;
			}
		} else {
			this.scheduledDropoffTimeVisibility = false;
		}
	}
	updateOrderVariables(order: OrderInterface, historyIndex: number = 0) {
		const statusHistory = order.statusHistory;
		//If history index 0 then take latest status or else previous status from status history
		if (NullEmptyChecker.isNonEmptyArray(statusHistory)) {
			const status = statusHistory[statusHistory.length - 1 - historyIndex].status;
			this.isOrderDelivered = ORDER_DELIVERED_CATEGORY.includes(status);
			this.isPendingDispatch = SystemStatus.includes(status);
			this.isOutForDelivery = OUT_FOR_DELIVERY_CATEGORY.includes(status);
			this.isOrderCancelled = ORDER_CANCELLED_CATEGORY.includes(status);
			this.isOrderExceptioned = ORDER_EXCEPTION.includes(status);
			this.isOrderDispatched = ORDER_DISPATCHED_CATEGORY.includes(status);
			this.isOrderFailed = ORDER_FAILED_CATEGORY.includes(status);
			this.isOrderUnAssigned = ORDER_UNASSIGNED === status;
			this.isOrderDelayed = ORDER_DELAYED === status;
			this.isOrderAtLocation = ORDER_AT_LOCATION === status;
			this.isOrderStaged = STAGED_CATEGORY.includes(status);
		}

		if (
			!this.isOrderDelivered &&
			!this.isPendingDispatch &&
			!this.isOrderUnAssigned &&
			!this.isOrderExceptioned &&
			!this.isOrderDelayed &&
			!this.isOutForDelivery &&
			!this.isOrderCancelled &&
			!this.isOrderDispatched &&
			!this.isOrderFailed &&
			!this.isOrderAtLocation &&
			!this.isOrderStaged &&
			NullEmptyChecker.isNonEmptyArray(statusHistory)
		) {
			// If all variables are still false means status is unknown, continue searching in the history
			this.updateOrderVariables(order, historyIndex + 1);
		} else {
			if (order.addOns)
				this.needToShowOrderAtLocationStatus = NullEmptyChecker.isNonEmptyArray(order.addOns) && order.addOns.includes(ADD_ONS.DROP_OFF_POINT);

			let indexofConfirmation = order.statusHistory.findIndex(status => status.status === ORDER_DISPATCHED);
			let indexOfPickup = order.statusHistory.findIndex(status => status.status === OUT_FOR_DELIVERY);
			let indexOfDelivery = order.statusHistory.findIndex(status => ORDER_DELIVERED_CATEGORY.includes(status.status));
			let indexOfCancellation = order.statusHistory.findIndex(status => ORDER_CANCELLED_CATEGORY.includes(status.status));
			let indexOfFailed = order.statusHistory.findIndex(status => ORDER_FAILED_CATEGORY.includes(status.status));
			let indexOfOrderException = order.statusHistory.findIndex(status => ORDER_EXCEPTION === status.status);
			const estimatedDeliveryTime = order.estimatedDeliveryTime;
			let indexOfOrderAtLocation = order.statusHistory.findIndex(status => ORDER_AT_LOCATION === status.status);

			const exceptionStatus = order.statusHistory[indexOfOrderException];
			if (exceptionStatus) {
				this.orderExceptionedOn = generateDatetimeToDisplayOnOrderProgressbar(exceptionStatus.updatedAtEpoch, order.timeZone);
			}

			if (estimatedDeliveryTime) {
				this.arrivingInTime = generateHumanReadableDatetimeFormat(estimatedDeliveryTime, new Date().getTime());
				this.estimatedDeliveryTime = generateDatetimeToDisplayOnOrderProgressbar(estimatedDeliveryTime, order.timeZone);
			}


			const deliveredStatus = order.statusHistory[indexOfDelivery];
			if (deliveredStatus) {
				this.orderDeliveredAt = generateDatetimeToDisplayOnOrderProgressbar(deliveredStatus.updatedAtEpoch, order.timeZone);
			}

			const cancelledStatus = order.statusHistory[indexOfCancellation];
			if (cancelledStatus) {
				this.orderCancelledOn = generateDatetimeToDisplayOnOrderProgressbar(cancelledStatus.updatedAtEpoch, order.timeZone);
			}

			const failedStatus = order.statusHistory[indexOfFailed];
			if (failedStatus) {
				this.orderFailedOn = generateDatetimeToDisplayOnOrderProgressbar(failedStatus.updatedAtEpoch, order.timeZone);
			}

			if (indexOfPickup > -1) {
				const orderPickedTime = order.statusHistory[indexOfPickup];
				this.orderPickedTime = generateDatetimeToDisplayOnOrderProgressbar(orderPickedTime.updatedAtEpoch, order.timeZone);
			} else {
				this.orderPickedTime = this.estimatedDeliveryTime;
			}

			if (indexofConfirmation > -1) {
				const orderConfirmedTime = order.statusHistory[indexofConfirmation];
				this.orderConfirmedTime = generateDatetimeToDisplayOnOrderProgressbar(orderConfirmedTime.updatedAtEpoch, order.timeZone);
			} else {
				this.orderConfirmedTime = this.estimatedDeliveryTime;
			}


			if (indexOfCancellation !== -1 && !this.isOrderDelivered && !this.isPendingDispatch && !this.isOutForDelivery && !this.isOrderDispatched) {
				this.orderCancelledBeforeConfirmation = indexofConfirmation === -1;
				this.orderCancelledBeforePickup = indexOfPickup === -1 && indexofConfirmation !== -1;
				this.orderCancelledBeforeDelivery = indexOfDelivery === -1 && indexOfPickup !== -1;
			}

			if (indexOfFailed > -1 && this.isOrderFailed) {
				this.orderCancelledBeforeConfirmation = true;
			}

			const orderAtLocationStatus = order.statusHistory[indexOfOrderAtLocation];
			if (orderAtLocationStatus) {
				this.orderAtLocationOn = generateDatetimeToDisplayOnOrderProgressbar(orderAtLocationStatus.updatedAtEpoch, order.timeZone);
			}

			this.setupOntimeDelayedIndicatorVisibility(order);
			this.setupDropoffTimeVisibility(order);
			this.orderStatusText = this.computeOrderStatus(order);
		}
	}

	private setOrderAttributeList(order: OrderInterface | undefined, liveTrackingConfig: LiveTrackingConfigInterface) {
		if (!order || !liveTrackingConfig?.componentVisibility?.orderAttributes?.active) {
			return;
		}

		const attributeConfig = liveTrackingConfig?.componentVisibility?.orderAttributes.attributes;
		if (attributeConfig) {
			const { orderAttributes } = order;
			for (let key in orderAttributes) {
				if (!attributeConfig[key].active) {
					continue;
				}

				this.orderAttributeList.push({
					key: attributeConfig[key]['name'],
					value: orderAttributes[key].toString()
				});
			}
		}

	}

	openModal(content: any) {
		this.modalService.open(content, {
		});
	}

	ngOnDestroy() {
		this.streamSource?.close();
	}
}
