import { Component, Input, OnInit } from '@angular/core';
import { AdConfig } from 'src/app/ts-interfaces/live-tracking-config.interface';

@Component({
  selector: 'ds-single-ads-banner',
  templateUrl: './single-ads-banner.component.html',
  styleUrls: ['./single-ads-banner.component.scss']
})
export class SingleAdsBannerComponent implements OnInit {
  @Input() adconfig!: AdConfig;

  constructor() { }

  ngOnInit(): void {
  }

}
