import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { LoaderService } from '../../services/loader.service';
import { OrderService } from '../../services/order.service';
import { ADD_ONS } from '../../shared/constants/generic-constants';
import { ORDER_DELIVERED_CATEGORY, SystemStatus, OUT_FOR_DELIVERY_CATEGORY, ORDER_CANCELLED_CATEGORY, ORDER_DISPATCHED_CATEGORY, ORDER_DISPATCHED, OUT_FOR_DELIVERY, ORDER_AT_LOCATION, STAGED_CATEGORY } from '../../shared/constants/order-statuses';
import { contrastColorOf } from '../../shared/functions/generate-contrast-color';
import { getDSPLogoURL } from '../../shared/functions/get-dsp-logo-url';
import { generateHumanReadableDatetimeFormat, generateDatetimeToDisplayOnOrderProgressbar, getTime, getDate, generateOrderDeliveryTime } from '../../shared/utils/date-utils';
import { LiveTrackingConfigInterface, AdConfig, BusinessLogo, HeaderComponent, LiveTrackingBrandingInterface } from '../../ts-interfaces/live-tracking-config.interface';
import { OrderInterface, ItemList } from '../../ts-interfaces/order.interface';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from 'src/app/services/translations.service';

@Component({
  selector: 'ds-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  public liveTrackingConfig: LiveTrackingConfigInterface | undefined;
	public liveTrackingBranding: LiveTrackingBrandingInterface | undefined;
  public secondaryAdsOne: AdConfig | undefined;
  public secondaryAdsTwo: AdConfig | undefined;
  public primaryAds: AdConfig | undefined;
  public order!: OrderInterface;
  getDSPLogoURL = getDSPLogoURL;
  public isClientToken = false;
  businessLogo: BusinessLogo | undefined;
  header: HeaderComponent | undefined;
  public isHeaderBackgroundWhite: boolean = false;
  public customerInitials: string = "";
  public customerAddress: string = "";
  desktopDeliveryInstructions: string = "";
  deliveryInstructions: string = "";
  readMoreOrLess = false;
  orderValueVisibility = false;
  orderItemsVisibility = false;
  packageItemList: ItemList[] = [];
  orderAttributeList: Array<{ key: string; value: string }> = [];


  public isPendingDispatch = false;
  public isOrderDelivered = false;
  public isOutForDelivery = false;
  public isOrderCancelled = false;
  public isOrderDispatched = false;
	public translationsLoaded = false;
  public estimatedDeliveryTime = "";
  public orderDeliveredAt = "";
  public orderCancelledOn = "";
  public arrivingInTime = "";
  public orderCancelledBeforeConfirmation = false;
  public orderCancelledBeforePickup = false;
  public orderCancelledBeforeDelivery = false;
  public isOrderOnTime = false;
  private validationToken: string | undefined;
  public orderHiddenFields: { [key: string]: boolean } = {};
  public isOrderAtLocation = false;
  public orderAtLocationOn = "";
  public isOrderStaged = false;
  public needToShowOrderAtLocationStatus = false;
	public onTimeDelayedIndicatorVisibility = true;
	public scheduledDropoffTimeVisibility = false;
	public scheduledDropOffTimeString = "";
	public orderStatusText: { text: string, time: string } = { text: '', time: '' };

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private loaderService: LoaderService,
		private translate: TranslateService,
		private translationsService: TranslationsService
  ) { }

  ngOnInit() {
    this.addListener();
  }

  private addListener() {
    this.validationToken = this.activatedRoute.snapshot.queryParams.valToken;
    this.orderService.googleMapsApiKey = this.activatedRoute.snapshot.queryParams.googleMapsApiKey;
    if (window.addEventListener) {
      window.addEventListener("message", this.receiveMessage.bind(this));
    } else {
      (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
    }
  }

  private receiveMessage(event: MessageEvent): void {
    if (!environment.appUrl.includes(event.origin)) {
      return
    }  
		if(event.data) {
			this.liveTrackingConfig = (event.data['liveTrackingConfig'] as LiveTrackingConfigInterface);
			this.liveTrackingBranding = (event.data['liveTrackingBranding'] as LiveTrackingBrandingInterface);
			const locale = event.data['locale'] as string;
			const xToken = event.data['token'] as string;
			const skipActiveConfigCheck = event.data['skipActiveConfigCheck'] as boolean;
			this.translationsService.get(locale, xToken, skipActiveConfigCheck).subscribe({
				next: (translations) => {
				  this.translate.setTranslation(locale, translations);
					this.translate.use(locale);
				},
				complete: () => {
					this.translationsLoaded = true;
				}
			});
		}
		this.order = this.orderService.getFakeOrder() as OrderInterface;
    this.loaderService.isPageLoading$.next(false);

    if (!this.order || !this.liveTrackingConfig || !this.liveTrackingBranding) {
      return;
    }

    this.setupTheme(this.liveTrackingBranding);
    this.setFontVariables(this.liveTrackingBranding);
    this.setUpAdsConfig(this.liveTrackingBranding);
    this.setOrderAttributeList(this.order, this.liveTrackingConfig);

    this.orderValueVisibility = !!this.order && this.liveTrackingConfig.componentVisibility.orderDetails.orderValue.active;
    this.orderItemsVisibility = !!this.order && this.liveTrackingConfig.componentVisibility.orderDetails.orderItems.active;
		this.scheduledDropoffTimeVisibility = this.liveTrackingConfig.componentVisibility.scheduledDropoffTime.active;
		this.onTimeDelayedIndicatorVisibility = this.liveTrackingConfig.componentVisibility.onTimeDelayedIndicator.active;
    this.orderHiddenFields = this.orderService.setHiddenFields(this.liveTrackingConfig);

    if (this.order?.redirectUrl) {
      window.open(this.order.redirectUrl, '_self');
    }

    if (this.order) {
			this.order.deliveryInstructions = this.translate.instant('GENERIC.COMMON_PAGES.PREVIEW_DELIVERY_INSTRUCTIONS');
			this.updateOrderVariables(this.order);
      const [firstName, secondName] = this.order.deliveryContact.name.split(' ');
      this.customerInitials = firstName.charAt(0).toUpperCase();
      if (secondName) {
        this.customerInitials += ` ${secondName.charAt(0).toUpperCase()}`;
      }

      this.customerAddress = this.order.deliveryAddress.street;
      if (this.order.deliveryAddress.street2) {
        this.customerAddress += ", ";
        this.customerAddress += this.order.deliveryAddress.street2;
      }

      this.customerAddress = `${this.customerAddress}, ${this.order.deliveryAddress.city}, ${this.order.deliveryAddress.state} ${this.order.deliveryAddress.zipcode}, ${this.order.deliveryAddress.country}`;

      if (this.order.deliveryInstructions) {
        this.deliveryInstructions = this.order.deliveryInstructions;
        this.desktopDeliveryInstructions = this.order.deliveryInstructions.slice(0, 134);
      }

      this.packageItemList = this.order.itemList || (this.order.packages && this.order.packages[0].itemList) || [];
    }

    this.businessLogo = this.liveTrackingBranding?.branding.businessLogos.find(logo => logo.size === "large");
    this.header = this.liveTrackingBranding?.components.header;
		this.translate.onLangChange.subscribe(() => {
			if (this.order) {
				this.order.deliveryInstructions = this.translate.instant('GENERIC.COMMON_PAGES.PREVIEW_DELIVERY_INSTRUCTIONS');
				if (this.order.deliveryInstructions) {
					this.deliveryInstructions = this.order.deliveryInstructions;
					this.desktopDeliveryInstructions = this.order.deliveryInstructions.slice(0, 134);
				}
				this.updateOrderVariables(this.order);
		  }
		})
  }

  // setup page theme. these css variables will be used cross page for proper brand experience
  private setupTheme(liveTrackingConfig: LiveTrackingBrandingInterface) {
    if (liveTrackingConfig.branding.color.primary) {
      const contrastColor = contrastColorOf(liveTrackingConfig.branding.color.primary);

      // primary color for site
      document.documentElement.style
        .setProperty('--primary', liveTrackingConfig.branding.color.primary);
      // when you use primary as background use this as font color
      document.documentElement.style
        .setProperty('--primary-contrast', contrastColor); // contrasting black or white
    }

    if (liveTrackingConfig.branding.color.background) {
      document.documentElement.style
        .setProperty('--header-bg', liveTrackingConfig.branding.color.background);

      const headerBg = liveTrackingConfig.branding.color.background;

      this.isHeaderBackgroundWhite = headerBg === '#fff' || headerBg === '#ffffff' || headerBg === 'white';
    }
  }

  // use fonts provider by brand for page
  private setFontVariables(liveTrackingConfig: LiveTrackingBrandingInterface) {
    const { branding } = liveTrackingConfig;

    let content = "", heading = "";

    if (branding.fonts.content) {
      const contentFontType = branding.fonts.content.split('.').pop();

      content = `@font-face {
        font-family: 'content_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${branding.fonts.content}) format('${contentFontType}');
      }`;
    }

    if (branding.fonts.heading) {
      const headingFontType = branding.fonts.heading.split('.').pop();

      heading = `@font-face {
        font-family: 'heading_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${branding.fonts.heading}) format('${headingFontType}');
      }`;
    }

    const style = document.createElement('style');
    style.innerHTML = `
      ${content}
      ${heading}
    `;

    document.head.appendChild(style);
  }

  private setUpAdsConfig(liveTrackingConfig: LiveTrackingBrandingInterface) {
    this.secondaryAdsOne = liveTrackingConfig.components.ads.find(ads => {
      return ads.type === "secondaryOne";
    });

    this.secondaryAdsTwo = liveTrackingConfig.components.ads.find(ads => {
      return ads.type === "secondaryTwo";
    });

    this.primaryAds = liveTrackingConfig.components.ads.find(ads => {
      return ads.type === "primary";
    });
  }

  private setOrderAttributeList(order: OrderInterface | undefined, liveTrackingConfig: LiveTrackingConfigInterface) {
    if (!order || !liveTrackingConfig.componentVisibility.orderAttributes.active) {
      return;
    }

    const types: any = {
      string: "sample string",
      array: "1, 2, 3, 4",
      number: '123',
      boolean: 'true'
    }

    const attributeConfig = liveTrackingConfig.componentVisibility.orderAttributes.attributes;

    for (let key in attributeConfig) {
      if (!attributeConfig[key].active) {
        continue;
      }

      this.orderAttributeList.push({
        key: attributeConfig[key]['name'],
        value: types[attributeConfig[key]['type']]
      });
    }

    if (!NullEmptyChecker.isNonEmptyArray(this.orderAttributeList)) {
			this.orderAttributeList = [
				{ key: "Service Name", value: types.string },
				{ key: "Gift Order", value: types.boolean },
			];
		}
  }

	/**
		 * Computes the order status based on the given order object.
		 * @param order - The order object containing various properties such as status, delivery time, and delivery address.
		 * @returns An object with the text and time values representing the order status.
		*/
	private computeOrderStatus(order: OrderInterface) {
		let requestedDeliveryTime;
		if (order.isDropoffASAP && order.dropoffTime?.endsAt) {
			requestedDeliveryTime = generateDatetimeToDisplayOnOrderProgressbar(order.dropoffTime?.endsAt, order.timeZone);
		} else {
			requestedDeliveryTime = generateDatetimeToDisplayOnOrderProgressbar(new Date(order.createdAt).getTime(), order.timeZone);
		}
		let orderStatus: { text: string, time: string };
		if (this.isPendingDispatch) {
			orderStatus = {
				text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_PENDING_DISPATCHED'),
				time: ''
			};
			return orderStatus;
		} else if (this.isOrderDispatched) {
			// if etd is present then show etd else show requestedDeliveryTime
			orderStatus = {
				text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_DISPATCHED_ETA'),
				time: `${this.estimatedDeliveryTime}`
			};
			return orderStatus;

		} else if (this.isOutForDelivery) {
			// if etd is present then show etd else show requestedDeliveryTime
			orderStatus = {
				text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_OUT_FOR_DELIVERY_ETA'),
				time: `${this.arrivingInTime}`
			};
			return orderStatus;
		} else if (this.isOrderAtLocation && this.needToShowOrderAtLocationStatus) {
			orderStatus = {
				text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_AT_LOCATION'),
				time: `${this.orderAtLocationOn}`
			};
			return orderStatus;
		} else if (this.isOrderDelivered) {
			orderStatus = {
				text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_DELIVERED'),
				time: `${this.orderDeliveredAt}`
			};
			return orderStatus;
		} else if (this.isOrderCancelled) {
			orderStatus = {
				text: this.translate.instant('LIVE_TRACKING.ORDER_STATUS_AND_TITLE.ORDER_CANCELLED'),
				time: `${this.orderCancelledOn}`
			}
			return orderStatus;
		} else {
			orderStatus = {
				text: '',
				time: ''
			};
			return orderStatus;
    }
	}

	/**
	 * Sets up the visibility for the on-time and delayed indicators based on the given order object.
	 * @param order - The order object containing the necessary information.
	*/
	private setupOntimeDelayedIndicatorVisibility(order: OrderInterface) {
		let indexOfDelivery = order.statusHistory.findIndex(statusDetails => ORDER_DELIVERED_CATEGORY.includes(statusDetails.status));
    const deliveredStatus = order.statusHistory[indexOfDelivery];
		const isDropoffASAP = order.isDropoffASAP;
		if (this.onTimeDelayedIndicatorVisibility) {
			const requestedDeliveryTime = order.dropoffTime?.endsAt;
			if (requestedDeliveryTime && !isDropoffASAP) {
				if (this.isOutForDelivery) {
					this.isOrderOnTime = new Date().getTime() < requestedDeliveryTime;
				} else if (this.isOrderDelivered || this.isOrderAtLocation) {
					if (order?.actualDeliveryTime) {
						this.isOrderOnTime = order.actualDeliveryTime < requestedDeliveryTime;
					} else if (deliveredStatus) {
						const deliveredAt = deliveredStatus.updatedAtEpoch;
						this.isOrderOnTime = deliveredAt < requestedDeliveryTime;
					} else {
						this.onTimeDelayedIndicatorVisibility = false;
					}
				}
			} else {
				this.onTimeDelayedIndicatorVisibility = false;
			}
		}
	}

	/**
	 * Sets up the visibility for the drop-off time based on the given order object.
	 * @param order - The order object containing the necessary information.
	*/
	private setupDropoffTimeVisibility(order: OrderInterface) {
		const isDropoffASAP = order.isDropoffASAP;
		if (this.scheduledDropoffTimeVisibility && !isDropoffASAP) {
			const scheduledDropoffTimeStartsAt = order.dropoffTime?.startsAt;
			const scheduledDropoffTimeEndsAt = order.dropoffTime?.endsAt;
			if (scheduledDropoffTimeStartsAt && scheduledDropoffTimeEndsAt) {
				const scheduledDropoffStartDate = getDate(scheduledDropoffTimeStartsAt, order.timeZone);
				const scheduledDropoffEndDate = getDate(scheduledDropoffTimeEndsAt, order.timeZone);
				const isOnSameDate = scheduledDropoffStartDate === scheduledDropoffEndDate;
				if (isOnSameDate) {
					const scheduledDropOffTimeStartsAt = getTime(scheduledDropoffTimeStartsAt, order.timeZone);
					const scheduledDropOffTimeEndsAt = getTime(scheduledDropoffTimeEndsAt, order.timeZone);
					this.scheduledDropOffTimeString = `${this.translate.instant('GENERIC.PLATFORM.ON_TEXT')} ${scheduledDropoffStartDate} ${scheduledDropOffTimeStartsAt} ${this.translate.instant('GENERIC.PLATFORM.TO_TEXT').toLowerCase()} ${scheduledDropOffTimeEndsAt}`;
				} else {
					const scheduledDropoffDateStartsAt = generateDatetimeToDisplayOnOrderProgressbar(scheduledDropoffTimeStartsAt, order.timeZone);
					const scheduledDropoffDateEndsAt = generateDatetimeToDisplayOnOrderProgressbar(scheduledDropoffTimeEndsAt, order.timeZone);
					this.scheduledDropOffTimeString = `${this.translate.instant('GENERIC.PLATFORM.BETWEEN_TEXT')} ${scheduledDropoffDateStartsAt} ${this.translate.instant('GENERIC.PLATFORM.AND_TEXT').toLowerCase()} ${scheduledDropoffDateEndsAt}`;
				}
			} else {
				this.scheduledDropoffTimeVisibility = false;
			}
		} else {
			this.scheduledDropoffTimeVisibility = false;
		}
	}
	updateOrderVariables(order: OrderInterface, historyIndex: number = 0) {
		const statusHistory = order.statusHistory;
		//If history index 0 then take latest status or else previous status from status history
		if (NullEmptyChecker.isNonEmptyArray(statusHistory)) {
			const status = statusHistory[statusHistory.length - 1 - historyIndex].status;
			this.isOrderDelivered = ORDER_DELIVERED_CATEGORY.includes(status);
			this.isPendingDispatch = SystemStatus.includes(status);
			this.isOutForDelivery = OUT_FOR_DELIVERY_CATEGORY.includes(status);
			this.isOrderCancelled = ORDER_CANCELLED_CATEGORY.includes(status);
			this.isOrderDispatched = ORDER_DISPATCHED_CATEGORY.includes(status);
			this.isOrderAtLocation = ORDER_AT_LOCATION === status;
			this.isOrderStaged = STAGED_CATEGORY.includes(status);
		}

		if (
			!this.isOrderDelivered &&
			!this.isPendingDispatch &&
			!this.isOutForDelivery &&
			!this.isOrderCancelled &&
			!this.isOrderDispatched &&
			!this.isOrderAtLocation &&
			!this.isOrderStaged &&
			NullEmptyChecker.isNonEmptyArray(statusHistory)
		) {
			// If all variables are still false means status is unknown, continue searching in the history
			this.updateOrderVariables(order, historyIndex + 1);
		} else {
			if (order.addOns)
				this.needToShowOrderAtLocationStatus = NullEmptyChecker.isNonEmptyArray(order.addOns) && order.addOns.includes(ADD_ONS.DROP_OFF_POINT);

			let indexofConfirmation = order.statusHistory.findIndex(status => status.status === ORDER_DISPATCHED);
			let indexOfPickup = order.statusHistory.findIndex(status => status.status === OUT_FOR_DELIVERY);
			let indexOfDelivery = order.statusHistory.findIndex(status => ORDER_DELIVERED_CATEGORY.includes(status.status));
			let indexOfCancellation = order.statusHistory.findIndex((status) =>
				ORDER_CANCELLED_CATEGORY.includes(status.status)
			);
			let indexOfOrderAtLocation = order.statusHistory.findIndex((status) => ORDER_AT_LOCATION === status.status);
			if (order.dropoffTime) {
				order.dropoffTime.endsAt = new Date().getTime() + 8 * 60 * 60 * 1000;
				order.dropoffTime.startsAt = new Date().getTime() + 2 * 60 * 60 * 1000;
			}
			if (order.estimatedDeliveryTime && order.estimatedDeliveryTime < new Date().getTime()) {
				order.estimatedDeliveryTime = new Date().getTime() + 7 * 60 * 60 * 1000;
			}

			if (order.estimatedDeliveryTime) {
				this.arrivingInTime = generateHumanReadableDatetimeFormat(order.estimatedDeliveryTime, new Date().getTime());

				this.estimatedDeliveryTime = generateDatetimeToDisplayOnOrderProgressbar(
					order.estimatedDeliveryTime,
					order.timeZone
				);
			}


			const deliveredStatus = order.statusHistory[indexOfDelivery];
			if (deliveredStatus) {
				this.orderDeliveredAt = generateDatetimeToDisplayOnOrderProgressbar(deliveredStatus.updatedAtEpoch, order.timeZone);
			}

			const cancelledStatus = order.statusHistory[indexOfCancellation];
			if (cancelledStatus) {
				this.orderCancelledOn = generateDatetimeToDisplayOnOrderProgressbar(cancelledStatus.updatedAtEpoch, order.timeZone);
			}



			if (indexOfCancellation !== -1 && !this.isOrderDelivered && !this.isPendingDispatch && !this.isOutForDelivery && !this.isOrderDispatched) {
				this.orderCancelledBeforeConfirmation = indexofConfirmation === -1;
				this.orderCancelledBeforePickup = indexOfPickup === -1 && indexofConfirmation !== -1;
				this.orderCancelledBeforeDelivery = indexOfDelivery === -1 && indexOfPickup !== -1;
			}



			const orderAtLocationStatus = order.statusHistory[indexOfOrderAtLocation];
			if (orderAtLocationStatus) {
				this.orderAtLocationOn = generateDatetimeToDisplayOnOrderProgressbar(orderAtLocationStatus.updatedAtEpoch, order.timeZone);
			}

			this.setupOntimeDelayedIndicatorVisibility(order);
			this.setupDropoffTimeVisibility(order);
			this.orderStatusText = this.computeOrderStatus(order);
    }
  }
}
