import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';
import { mapMonoChromeBackground } from 'src/app/shared/constants/google-map-constants';
import { getDSPLogoURL } from 'src/app/shared/functions/get-dsp-logo-url';
import { OrderInterface } from 'src/app/ts-interfaces/order.interface';

import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { OrderService } from 'src/app/services/order.service';
import { TranslateService } from '@ngx-translate/core';
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  selector: 'ds-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnChanges {
  @Input() order!: OrderInterface;
  @ViewChild('mapContainer', { static: false }) gmap!: ElementRef;

  private scaledSize!: google.maps.Size;
  private driverLocation: google.maps.LatLngLiteral | undefined;
  private storeLocation: google.maps.LatLngLiteral | undefined;
  private customerLocation: google.maps.LatLngLiteral | undefined;
  private driverInfoWindow: google.maps.InfoWindow | undefined;
  public inFullscreenMode = false;
  map!: google.maps.Map;
  private driverMarker: google.maps.Marker | undefined;
  private flightPath: google.maps.Polyline | undefined = undefined;

  constructor(private orderService: OrderService, private translate: TranslateService) { }

  ngOnInit(): void {
    document.addEventListener("fullscreenchange", () => {
      this.inFullscreenMode = !this.inFullscreenMode;
      // fullscreen takes time, so wait for it before we set fitbounds
      // also give cool zoom-in animation
      setTimeout(() => {
        this.setFitBounds();
      }, 1000);
    });
  }

  ngAfterViewInit() {
    let url = 'https://maps.googleapis.com/maps/api/js';
    const googleMapsApiKey = this.orderService.getGoogleMapsApiKey();

    if (googleMapsApiKey) {
      url += '?key=' + googleMapsApiKey;
    }

    const mapScriptElem = document.createElement('script');
    mapScriptElem.setAttribute("nonce", "gTag4");
    mapScriptElem.src = url;
    mapScriptElem.onload = () => {
      this.initializeMap();
    }
    document.head.appendChild(mapScriptElem);
  }

  initializeMap() {
    // create map object
    this.map = new google.maps.Map(this.gmap.nativeElement, {
      center: { lat: 39.655941352852984, lng: -100.3311623125 },
      zoom: 3,
      streetViewControl: false,
      mapTypeControlOptions: {
        mapTypeIds: ["styled_map"]
      },
      fullscreenControl: false
    });

    // make map black and white
    //https://stackoverflow.com/questions/4003578/google-maps-in-grayscale
    const mapStyle = new google.maps.StyledMapType(mapMonoChromeBackground, { name: "Grayscale" });
    this.map.mapTypes.set('grey', mapStyle);
    this.map.setMapTypeId('grey');

    // set the sizes of icons
    this.scaledSize = new google.maps.Size(40, 45);

    this.setStoreMarker();
    this.setCustomerMarker();
    this.setDriverMarker();

    this.setFitBounds();
  }

  setFitBoundsOnMapFullscreenToggle() {
    if (this.inFullscreenMode) {
      document.exitFullscreen()
    } else {
      const mapContainerElement = document.getElementById('map-container');
      mapContainerElement?.requestFullscreen();
    }
  }

  setStoreMarker() {
    this.storeLocation = { lat: +this.order.pickUpAddress.latitude, lng: +this.order.pickUpAddress.longitude };

    const storeMarker = new google.maps.Marker({
      position: this.storeLocation,
      map: this.map,
      icon: { url: '/assets/icons/store-anchor.svg', scaledSize: this.scaledSize }
    });
    storeMarker.setMap(this.map);

    const address = this.order.pickUpAddress;

    let storeAddress = address.street2 ? `${address.street}, ${address.street2}` : address.street;
    storeAddress = `${storeAddress}<br>${address.city}, ${address.state} ${address.zipcode}<br>${address.country}`;

    const infoWindow = new google.maps.InfoWindow({
      content: `<b>${this.order.store.name}</b><br>${storeAddress}`
    });

    storeMarker.addListener('mouseover', () => {
      infoWindow.open(this.map, storeMarker);
    });

    storeMarker.addListener('mouseout', () => {
      infoWindow.close();
    });
  }

  setCustomerMarker() {
    if (!this.order.deliveryAddress) {
      return;
    }
    this.customerLocation = { lat: +this.order.deliveryAddress.latitude, lng: +this.order.deliveryAddress.longitude };

    const customerMaker = new google.maps.Marker({
      position: this.customerLocation,
      map: this.map,
      icon: { url: '/assets/icons/home-user.svg', scaledSize: this.scaledSize }
    });
    customerMaker.setMap(this.map);

    const address = this.order.deliveryAddress;

    let customerAddress = address.street2 ? `${address.street}, ${address.street2}` : address.street;
    customerAddress = `${customerAddress}<br>${address.city}, ${address.state} ${address.zipcode}<br>${address.country}`;

    const infoWindow = new google.maps.InfoWindow({
      content: `<b>${this.order.deliveryContact.name}</b><br>${customerAddress}`
    });

    customerMaker.addListener('mouseover', () => {
      infoWindow.open(this.map, customerMaker);
    });

    customerMaker.addListener('mouseout', () => {
      infoWindow.close();
    });
  }

  setDriverMarker() {
    if (this.order?.lastLocation?.latitude && window.google) {
      this.driverLocation = { lat: +this.order.lastLocation.latitude, lng: +this.order.lastLocation.longitude };
      this.scaledSize = new google.maps.Size(40, 45);
      const driverMarker = new google.maps.Marker({
        position: this.driverLocation,
        map: this.map,
        icon: {
          url: getDSPLogoURL(this.order.carrier?.providerInfo.name || this.order.provider || this.order.preferredProvider) || '/assets/icons/driver-car.svg',
          scaledSize: this.scaledSize,
        }
      });

      driverMarker.setMap(this.map);
      this.driverMarker = driverMarker;

      // HACK
      // this.order.route must come from streaming service.
      if (!this.order.route) {
        this.order.route = [];
      }
      this.order.route.push(this.order.lastLocation);

      this.setPolyline();
      this.setupDriverInfoWindow();
    }
  }

  setPolyline() {
    if (!Array.isArray(this.order.route)) {
      return;
    }

    const lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      fillColor: "#2C7BE5",
      strokeColor: "#2C7BE5",
      fillOpacity: 1,
      strokeWeight: 3
    };
    const circleSymbol = {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "#2C7BE5",
      strokeColor: "#2C7BE5",
      fillOpacity: 10,
      strokeWeight: 4
    };

    const coordinateList = this.order.route.map(routes => ({
      lat: +(routes.latitude || 0),
      lng: +(routes.longitude || 0)
    }));

    this.flightPath = new google.maps.Polyline({
      path: coordinateList,
      geodesic: true,
      strokeColor: "#2C7BE5",
      strokeOpacity: 10,
      strokeWeight: 1.5,
      icons: [
        {
          icon: lineSymbol,
          offset: "100%",
          repeat: '80px',
        },
        {
          icon: circleSymbol,
          offset: "100%",
          repeat: '100px',
        },
      ],
    });
    this.flightPath.setMap(this.map);
  }

  setupDriverInfoWindow() {
    if (!this.driverMarker || !this.order.estimatedDeliveryTime) {
      return;
    }

    let estimatedDeliveryTime = dayjs.tz(this.order.estimatedDeliveryTime, this.order.timeZone);
    let delta = estimatedDeliveryTime.from(dayjs().tz(this.order.timeZone), true);

    let { vehicle, driver } = this.order;

    this.driverInfoWindow = new google.maps.InfoWindow({
      content: `<span class="text-secondary">ETA:</span> ${delta}<br>
      ${driver?.name ? `<span class="text-secondary">${this.translate.instant('GENERIC.PLATFORM.DRIVER')}:</span> <b class="text-dark">${driver?.name}</b><br>` : ''}
      ${vehicle?.type ? `<span class="text-secondary">${vehicle?.type}</span>${vehicle?.color ? ` (${vehicle?.color})` : ''}` : ""}
      `
    });

    this.driverMarker.addListener('mouseover', () => {
      this.driverInfoWindow!.open(this.map, this.driverMarker);
    });

    this.driverMarker.addListener('mouseout', () => {
      this.driverInfoWindow!.close();
    });

  }

  updateDriverLocationAndInfoWindow() {
    if (!this.order.lastLocation?.latitude || !this.order.lastLocation?.longitude) {
      return;
    }

    this.driverLocation = { lat: +this.order.lastLocation.latitude, lng: +this.order.lastLocation.longitude };

    // this must be available in this method, since the other condition is is handled by setDriverMarker method
    this.driverMarker!.setPosition(this.driverLocation);

    if (this.order.estimatedDeliveryTime) {
      let estimatedDeliveryTime = dayjs.tz(this.order.estimatedDeliveryTime, this.order.timeZone);
      let delta = estimatedDeliveryTime.from(dayjs().tz(this.order.timeZone), true);

      this.driverInfoWindow ? this.driverInfoWindow.setContent(`<div">ETA ${delta}</div>`) : this.setupDriverInfoWindow();
    }

    // HACK
    // this.order.route must come from streaming service.
    if (!this.order.route) {
      this.order.route = [];
    }
    this.order.route.push(this.order.lastLocation);

    if (!this.flightPath) {
      this.setPolyline();
    } else {
      const coordinates = this.order.route.map(routes => ({
        lat: +(routes.latitude || 0),
        lng: +(routes.longitude || 0)
      }));
      this.flightPath.setPath(coordinates);
    }
  }

  updateDriverMarker() {
    if (!this.driverMarker) {
      this.setDriverMarker();
    } else {
      this.updateDriverLocationAndInfoWindow();
    }
  }

  setFitBounds() {
    let minLatLng = {
      lat: 90,
      lng: 180
    }
    let maxLatLng = {
      lat: -90,
      lng: -180
    }

    const allLocations = [this.storeLocation, this.customerLocation, this.driverLocation];

    this.order.route?.forEach(item => {
      allLocations.push({ lat: +(item.latitude || 0), lng: +(item.longitude || 0) });
    });

    for (let item of allLocations) {
      if (!item) {
        continue;
      }

      if (item.lng < minLatLng.lng) {
        minLatLng.lng = item.lng
      }
      if (item.lng > maxLatLng.lng) {
        maxLatLng.lng = item.lng
      }
      if (item.lat < minLatLng.lat) {
        minLatLng.lat = item.lat
      }
      if (item.lat > maxLatLng.lat) {
        maxLatLng.lat = item.lat
      }
    }

    // adding 2 lat lng more to the viewport boundaries
    minLatLng.lng = Math.max(minLatLng.lng - 0.001, -180);
    minLatLng.lat = Math.max(minLatLng.lat - 0.001, -90);
    maxLatLng.lng = Math.min(maxLatLng.lng + 0.001, 180);
    maxLatLng.lat = Math.min(maxLatLng.lat + 0.001, 90);

    let sw = new google.maps.LatLng(minLatLng.lat, minLatLng.lng)
    let ne = new google.maps.LatLng(maxLatLng.lat, maxLatLng.lng)

    let latLngBounds = new google.maps.LatLngBounds()
    latLngBounds.extend(sw);
    latLngBounds.extend(ne);

    this.map.fitBounds(latLngBounds);
  }

  ngOnChanges(changes: SimpleChanges) {
    // pickup order types don't have driver location
    if (changes['order'].previousValue) { // makes sure following code is not run before component is fully initialised
      this.updateDriverMarker();
      this.setFitBounds(); // call fitbounds again when driver location is updated
    }
  }
}
