import { Injectable } from '@angular/core';
import {
  Resolve,
} from '@angular/router';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { OrderService } from "../order.service";
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from '../translations.service';

@Injectable({
  providedIn: 'root'
})
export class OrderDetailsResolver implements Resolve<any> {
  constructor(
    private orderService: OrderService,
    private translate: TranslateService,
    private translationsService: TranslationsService
  ) {}

  resolve(): Observable<any> {
    const orderId = window.location.pathname.substring(1);

    return this.orderService.get(orderId).pipe(
      switchMap(orderDetails => this.handleOrderDetailsSuccess(orderDetails)),
      catchError(() => this.handleOrderDetailsFailure())
    );
  }

  private handleOrderDetailsSuccess(orderDetails: any): Observable<any> {
    const locale = orderDetails.order?.locale || 'en';
    return this.translationsService.get(orderDetails.order?.locale).pipe(
      map(translations => {
        if (translations) {
          this.translate.setTranslation(locale, translations, true);
          this.translate.use(locale); 
        }
        return orderDetails;
      }),
      catchError(error => {
        return of(orderDetails);
      })
    );
  }


  private handleOrderDetailsFailure(): Observable<any> {
    return this.translationsService.get().pipe(
      map(translations => {
        if (translations) {
          this.translate.setTranslation('en', translations, true);
          this.translate.use('en');
        }
        return of("NOT_FOUND");
      }),
      catchError(error => {
        return of("NOT_FOUND");
      })
    );
  }
}
