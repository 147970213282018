import { Component, Input, OnInit } from '@angular/core';
import { Broadcast, LiveTrackingBrandingInterface } from 'src/app/ts-interfaces/live-tracking-config.interface';
import { OrderInterface } from 'src/app/ts-interfaces/order.interface';

@Component({
  selector: 'ds-broadcast-message',
  templateUrl: './broadcast-message.component.html',
  styleUrls: ['./broadcast-message.component.scss']
})
export class BroadcastMessageComponent implements OnInit {
  @Input() liveTrackingBranding!: LiveTrackingBrandingInterface;

  public showBCMessage = false;
  public show = false;
  public broadcast: Broadcast = {
    active: false,
    header: '',
    body: ''
  };

  constructor() { }

  ngOnInit(): void {
    const { broadcast } = this.liveTrackingBranding.components;
    this.broadcast = broadcast;

    let val = broadcast.active;
    let bcMessage = this.getCopyOfBCMessage(); // get a copy of current message
    try {

      // this checks if broadcast message has changed or not. if it did change then we need to show the message despite user having dismissed it before.
      let savedBCMessage = JSON.parse(window.localStorage.getItem(this.liveTrackingBranding.brandName + "showBCMessage") || '');
      if (!savedBCMessage) {
        val = true;
      } else if (savedBCMessage.header !== bcMessage.header || savedBCMessage.body !== bcMessage.body) {
        val = true;
      } else {
        val = false;
      }
    } catch { }

    this.showBCMessage = val;
  }

  public closeBCMessage() {
    this.showBCMessage = false;
    let broadCastMessage = this.getCopyOfBCMessage();
    window.localStorage.setItem(this.liveTrackingBranding.brandName + "showBCMessage", JSON.stringify(broadCastMessage));
  }

  getCopyOfBCMessage() {
    let broadCastMessage = { ...this.liveTrackingBranding.components.broadcast } as any; // hack, else vs code will cry for delete on the next line since active ain't optional key
    delete broadCastMessage.active;
    return broadCastMessage;
  }
}
