import { Component } from '@angular/core';
import { Router, ResolveEnd } from "@angular/router";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { LoaderService } from './services/loader.service';
import { environment } from "src/environments/environment";
import { Userpilot } from "userpilot";
import { TranslateService } from '@ngx-translate/core';

Userpilot.initialize(environment.userPilotAuthKey);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private router: Router, public loaderService: LoaderService, private translate: TranslateService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    // on first page load remove loading spinner
    this.router.events
      .subscribe(
        (event) => {
          if (event instanceof ResolveEnd && !event.url.match('/preview/')) {
            loaderService.isPageLoading$.next(false);
          }
        }
      );
  }
}