import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { catchError, Observable, of, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from '../translations.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationsResolver implements Resolve<void> {
  constructor(
    private translate: TranslateService,
    private translationsService: TranslationsService
  ) {}

  resolve(): Observable<any> {
    return this.translationsService.get().pipe(
      tap(translations => {
        this.handleTranslationsSuccess(translations);
      }),
			catchError(() => { 
        return of('NOT_FOUND'); 
      })
    );
  }

  private handleTranslationsSuccess(translations: any): void {
    if (translations) {
      this.translate.setTranslation('en', translations, true);
      this.translate.use('en');
    }
  }
}
