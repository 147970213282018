import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject } from "rxjs";
import { FeedBackForm } from "../components/feedback-box/feedback-box.component";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends BaseService {
  public isPreview: boolean = false;
  public fakeSuccess$ = new BehaviorSubject({ message: 'success' });

  constructor(private http: HttpClient) {
    super();
    this.isPreview = window.location.pathname.includes('/preview');
  }

  sendFeedback(form: any, orderId: string) {
    if (this.isPreview) {
      return of(this.fakeSuccess$);
    }

    form.source = "live-tracking";
    const headers = new HttpHeaders({ 'h-token': 'null' });

    return this.http.post(`${this.serverRootPathV2}feedback/${orderId}`, form, { headers });
  }
}
