import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { LiveTrackingConfigInterface } from '../ts-interfaces/live-tracking-config.interface';

// this service should be executed in any component that is loaded in app component the very first time

@Injectable({
	providedIn: "root",
})
export class GoogleAnalyticsService {
	constructor() {}

	setupGoogleAnalytics(liveTrackingConfig: LiveTrackingConfigInterface) {
		if (!liveTrackingConfig.trackingTools) {
			return;
		}

		const googleAnalytics = liveTrackingConfig.trackingTools.find((item) => item.id === "google-analytics");

		// check if google analytics is  configured for a brand
		if (googleAnalytics && googleAnalytics.value) {
			// register google analytics
			const gaScript = document.createElement("script");
			gaScript.async = true;
			gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalytics.value}`;
			document.head.appendChild(gaScript);

			const gaScript2 = document.createElement("script");
			gaScript2.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments)};
      gtag('js', new Date());
      gtag('config', '${googleAnalytics.value}', {cookie_flags: 'secure;samesite=none;'});`;
			document.head.appendChild(gaScript2);
		}
	}

	setupDSGoogleAnalytics() {
		// register DS google tag manager
		if (environment.googleTagmanagerID) {
			const gaScript3 = document.createElement("script");
			gaScript3.setAttribute("nonce", "gTag2");
			gaScript3.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${environment.googleTagmanagerID}');`;
			document.head.appendChild(gaScript3);
		}
	}
}
