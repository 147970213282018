import { Component, Input, OnInit } from '@angular/core';
import { OrderTypeController } from '@deliverysolutions/order-status-mapping';
import { LiveTrackingConfigInterface } from 'src/app/ts-interfaces/live-tracking-config.interface';
import { OrderInterface } from 'src/app/ts-interfaces/order.interface';

@Component({
  selector: 'ds-store-driver-info',
  templateUrl: './store-driver-info.component.html',
  styleUrls: ['./store-driver-info.component.scss']
})
export class StoreDriverInfoComponent implements OnInit {
  @Input() order!: OrderInterface;
  @Input() liveTrackingConfig!: LiveTrackingConfigInterface;

  public isDriverActive = false;
  public isDriverPhoneVisible = false;
  public isDriverLocationVisible = false;
  public storeAddress = "";
  public isStoreLocationVisible = false;
  public isStorePhoneVisible = false;
  public isPickupOrder = false;
  
  constructor() { }

  ngOnInit(): void {
    this.isPickupOrder = this.order.type === OrderTypeController.IN_STORE_PICKUP || this.order.type === OrderTypeController.CURBSIDE;

    this.isDriverActive = this.liveTrackingConfig.componentVisibility.driver.active;
    this.isDriverPhoneVisible = this.liveTrackingConfig.componentVisibility.driver.communication.isActiveCall;
    this.isDriverLocationVisible = this.liveTrackingConfig.componentVisibility.driver.communication.isActiveLocation;
    this.isStoreLocationVisible = this.liveTrackingConfig.componentVisibility.pickupLocation.communication.isActiveLocation;
    this.isStorePhoneVisible = this.liveTrackingConfig.componentVisibility.pickupLocation.communication.isActiveCall;

    this.storeAddress = this.order.pickUpAddress.street;
    if (this.order.pickUpAddress.street2) {
      this.storeAddress += ", ";
      this.storeAddress += this.order.pickUpAddress.street2;
    }

    this.storeAddress = `${this.storeAddress}, ${this.order.pickUpAddress.city}, ${this.order.pickUpAddress.state} ${this.order.pickUpAddress.zipcode}, ${this.order.pickUpAddress.country}`;
  }

}
