import { Directive, HostListener, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Userpilot } from "userpilot";

const parsePathFromRoute = (router: Router) => {
	try {
		const url: string = router.url;
		const splittedUrl = url.split("?");
		if (splittedUrl.length > 1) {
			const mongoIdRegex = /^[\/0-9a-fA-F]{25}$/;
			return splittedUrl[0].match(mongoIdRegex) ? "/:orderId" : splittedUrl[0];
		}
		return url;
	} catch (e) {
		return "";
	}
};

@Directive({
	selector: "[captureEvent]",
})
export class CaptureEventDirective {
	constructor(private router: Router, private route: ActivatedRoute) {}

	@Input() captureEvent = "";

	@HostListener("click", ["$event"]) onBtnClick() {
		let path = `${this.route.snapshot.parent?.routeConfig?.path || ""}/${this.route.snapshot.routeConfig?.path || ""}`;
		if (!path || path === "/") {
			path = parsePathFromRoute(this.router);
		}
		Userpilot.track(this.captureEvent, {
			page: path,
		});
		Userpilot.track("Page-" + path, {
			event: this.captureEvent,
		});
	}
}
