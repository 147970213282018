<router-outlet>
</router-outlet>

<div class="spinner d-flex align-items-center justify-content-center" *ngIf="loaderService.isPageLoading$.value">
  <div class="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
