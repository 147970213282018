import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from "./components/home/home.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { PreviewComponent } from './components/preview/preview.component';
import { ServiceUnavailableComponent } from "./components/service-unavailable/service-unavailable.component";
import { OrderDetailsResolver } from "./services/resolvers/order.resolver";
import { TranslationsResolver } from './services/resolvers/translations.resolve';

const routes: Routes = [
  {
    path: ":orderId",
    resolve: {
      data: OrderDetailsResolver
    },
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: "preview/:tenantId/:brandExternalId",
    component: PreviewComponent,
    pathMatch: 'full'
  },
  { 
    path: 'service-unavailable',
    resolve: {
      data: TranslationsResolver
    }, 
    component: ServiceUnavailableComponent 
  },
  { 
    path: '**', 
    resolve: {
      data: TranslationsResolver
    },
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
