<div class="imagesContainer"
     #imagesContainer>
  <ngb-carousel *ngIf="images"
                #carousel>
    <ng-template ngbSlide
                 *ngFor="let image of images; let imageIndex = index;">
      <div class="picsum-img-wrapper">
        <img [ngStyle]="imageDimensions"
             [src]="image"
             alt="deliveryProof">
      </div>
    </ng-template>
  </ngb-carousel>
  <div class="d-flex justify-content-center p-1 mt-2">{{slideIndex+1}} of {{images.length}}</div>
</div>