import { Injectable } from '@angular/core';
import { BaseService } from "./base.service";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService extends BaseService {
  private cacheTranslations: any = null;
  constructor(private httpClient: HttpClient) {
    super();
  }

  get(locale?: string, xToken?: string, skipActiveConfigCheck? : boolean): Observable<any> {
    if (this.cacheTranslations) {
      return of(this.cacheTranslations);
    }
    const getOrderAndConfigUrl = this.serverRootPathV2 + "translations/content/live-tracking";
    let params = new HttpParams();
    let headers = new HttpHeaders();
    if (locale) {
      params = params.set('preferredLocale', locale);
    }
    if(skipActiveConfigCheck) {
      params = params.set('skipActiveConfigCheck', true);
    }
    if (xToken) {
      headers = headers.set('x-token', xToken);
    }
    return this.httpClient.get(getOrderAndConfigUrl, { params, headers }).pipe(
      tap(translations => this.cacheTranslations = translations)
    );;
  }

  clearCache(): void {
    this.cacheTranslations = null;
  }
}
