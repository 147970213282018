<!-- Header section start -->
<div
	class="header-cnt pt-4 mb-4"
	*ngIf="businessLogo && header"
>
	<div class="container-fluid max-950px">
		<div class="row">
			<div class="col">
				<a
					class="d-block py-14px"
					[href]="businessLogo.externalLink"
					target="_blank"
					rel="noopener noreferrer"
					[ngClass]="{
						'text-end pull-right': header.logoAlign === 'right',
						'text-center pull-center': header.logoAlign === 'center',
						'text-start pull-left': header.logoAlign === 'left'
					}" [captureEvent]="'click-live-tracking-logo'"
				>
					<img
						[src]="businessLogo.url"
						height="70"
						[alt]="liveTrackingConfig?.brandName + ' logo'"
					/>
				</a>
			</div>
		</div>
	</div>
</div>
<!-- Header section end -->

<!-- Broadcast section start -->
<ds-broadcast-message
	*ngIf="liveTrackingBranding"
	[liveTrackingBranding]="liveTrackingBranding"
	[captureEvent]="'click-live-tracking-broadcast'"
></ds-broadcast-message>
<!-- Broadcast section end -->

<!-- order title and dsp log -- start -->
<div
	class="container-fluid max-950px mb-14px order-title-cnt"
	*ngIf="order"
>
	<div class="row">
		<div class="col">
			<div class="d-flex justify-content-between order-details border-radius-5px px-3">
				<div class="word-break-all me-3">{{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} {{ 'GENERIC.PLATFORM.ID_TEXT' | translate }}: {{ order.orderExternalId }}</div>

				<div *ngIf="providerName" class="d-flex align-items-center">
					<span class="dsp-title">{{ providerName }}</span>

					<img
						width="30"
						height="30"
						[src]="getDSPLogoURL(providerName)"
						alt="{{ providerName + ' logo' }}"
						class="dsp-logo ms-1"
					/>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- order title and dsp log -- end -->

<!-- compliance section start -->
<app-compliance-info-box-section
	*ngIf="liveTrackingConfig"
	[order]="order"
	[liveTrackingConfig]="liveTrackingConfig"
></app-compliance-info-box-section>
<!-- compliance section end -->

<!-- order status info - start -->
<div
	class="container-fluid max-950px"
	*ngIf="order"
>
	<div class="row">
		<div class="col">
			<div class="order-status-info-cnt">
				<div class="text-center">
					<h2
						class="order-status-title"
						[ngClass]="{ 'text-danger': isOrderCancelled || isOrderFailed || isOrderExceptioned }"
					>
							{{ orderStatusText.text }} <span
								*ngIf="orderStatusText.time"
								class="highlighted"
								[ngClass]="{ 'text-danger': isOrderExceptioned, 'delayed': !isOrderOnTime|| isOrderDelayed}"
							>{{ orderStatusText.time }}</span>
							<span
								class="px-2 py-1 border-radius-5px order-on-time text-nowrap"
								[ngClass]="{'delayed': !isOrderOnTime}"
								*ngIf="onTimeDelayedIndicatorVisibility"
							>{{ (isOrderOnTime ? 'GENERIC.PLATFORM.ON_TIME_TEXT' : 'GENERIC.PLATFORM.DELAYED_TEXT') | translate | uppercase }}</span>
					</h2>
					<p class="order-status-text pb-5 my-0">
						<ng-container *ngIf="(isOutForDelivery || isOrderDispatched) && scheduledDropoffTimeVisibility">
							{{ scheduledDropOffTimeString }}
						</ng-container>
						&nbsp;
					</p>

					<p class="order-status-text">
						<ng-container *ngIf="isPendingDispatch"> {{ 'LIVE_TRACKING.STATUS_BAR.PENDING_DISPATCH_TEXT' | translate }} </ng-container>
						<ng-container *ngIf="isOrderStaged"> {{ 'LIVE_TRACKING.STATUS_BAR.ORDER_STAGED_TEXT' | translate }} </ng-container>
						<ng-container *ngIf="isOutForDelivery"> {{ 'LIVE_TRACKING.STATUS_BAR.OUT_FOR_DELIVERY_TEXT' | translate }} </ng-container>
						<ng-container *ngIf="isOrderDelivered"> {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} {{ 'GENERIC.PLATFORM.DELIVERED' | translate }} </ng-container>
						<ng-container *ngIf="isOrderCancelled"> {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} {{ 'GENERIC.PLATFORM.CANCELLED' | translate }} </ng-container>
						<ng-container *ngIf="isOrderAtLocation && needToShowOrderAtLocationStatus"> {{ 'LIVE_TRACKING.STATUS_BAR.ORDER_AT_LOCATION_TEXT' | translate }}
						</ng-container>
						<ng-container *ngIf="isOrderDispatched && !isOrderUnAssigned"> {{ 'LIVE_TRACKING.STATUS_BAR.ORDER_DISPATCHED_TEXT' | translate }} </ng-container>
						<ng-container *ngIf="isOrderExceptioned"> {{ 'LIVE_TRACKING.STATUS_BAR.ORDER_EXCEPTION_TEXT' | translate }} </ng-container>
						<ng-container *ngIf="isOrderUnAssigned"> {{ 'LIVE_TRACKING.STATUS_BAR.ORDER_UNASSIGNED_TEXT' | translate }}</ng-container>
					</p>
				</div>

				<div class="order-status-progressbar d-flex mx-auto mb-4">
					<div class="steps d-flex flex-column align-items-center">
						<div
							class="status-circle-cnt d-flex justify-content-around align-items-center start w-100 position-relative"
							[ngClass]="{
								success:
									isOrderDispatched ||
									isOutForDelivery ||
									isOrderDelivered ||
									orderCancelledBeforePickup ||
									orderCancelledBeforeDelivery ||
									isOrderAtLocation ||
									isOrderExceptioned,
								fail: orderCancelledBeforeConfirmation
							}"
						>
							<div class="status-circle mx-auto">
								<span class="cross-mark">✖</span>
								<span class="check-mark">✔</span>
							</div>
						</div>
						<div
							class="status-text mt-2 ctooltip ctooltip-bottom"
							[attr.data-tooltip-success]="('LIVE_TRACKING.STATUS_BAR_TOOLTIP.ORDER_CONFIRMED_ON' | translate) + ' ' + orderConfirmedTime"
							[attr.data-tooltip-fail]="('LIVE_TRACKING.STATUS_BAR_TOOLTIP.ORDER_CANCELLED_ON' | translate ) + ' ' + orderCancelledOn"
						>
							{{ 'GENERIC.PLATFORM.CONFIRMED' | translate }}
						</div>
					</div>
					<div class="steps d-flex flex-column align-items-center">
						<div
							class="
								status-circle-cnt
								d-flex
								justify-content-around
								align-items-center
								middle
								w-100
								position-relative
								fail
							"
							[ngClass]="{
								success: isOutForDelivery || isOrderDelivered || orderCancelledBeforeDelivery || isOrderAtLocation,
								fail: orderCancelledBeforePickup || orderCancelledBeforeConfirmation || isOrderExceptioned
							}"
						>
							<div class="status-circle mx-auto">
								<span class="cross-mark">✖</span>
								<span class="check-mark">✔</span>
							</div>
						</div>
						<div
							class="status-text mt-2 ctooltip ctooltip-bottom"
							[attr.data-tooltip-success]="('LIVE_TRACKING.STATUS_BAR_TOOLTIP.ORDER_PICKED_ON' | translate) + ' ' + orderPickedTime"
							[attr.data-tooltip-fail]="('LIVE_TRACKING.STATUS_BAR_TOOLTIP.ORDER_CANCELLED_ON' | translate ) + ' ' + orderCancelledOn"
						>
							{{ 'GENERIC.PLATFORM.PICKED' | translate }}
						</div>
					</div>
					<div
						class="steps d-flex flex-column align-items-center"
						*ngIf="needToShowOrderAtLocationStatus"
					>
						<div
							class="status-circle-cnt d-flex justify-content-around align-items-center middle w-100 position-relative"
							[ngClass]="{success: isOrderDelivered || isOrderAtLocation,fail: orderCancelledBeforeDelivery || orderCancelledBeforePickup || orderCancelledBeforeConfirmation}"
						>
							<div class="status-circle mx-auto">
								<span class="check-mark">✔</span>
								<span class="cross-mark">✖</span>
							</div>
						</div>
						<div
							class="status-text mt-2 ctooltip ctooltip-bottom text-center"
							[attr.data-tooltip-success]="('LIVE_TRACKING.STATUS_BAR_TOOLTIP.ORDER_AT_LOCATION_ON' | translate) + ' ' + orderAtLocationOn"
							[attr.data-tooltip-fail]="('LIVE_TRACKING.STATUS_BAR_TOOLTIP.ORDER_CANCELLED_ON' | translate ) + ' ' + orderCancelledOn"
						>
							{{ 'GENERIC.PLATFORM.ORDER_AT_LOCATION' | translate }}
						</div>
					</div>
					<div class="steps d-flex flex-column align-items-center">
						<div
							class="status-circle-cnt d-flex justify-content-around align-items-center end w-100 position-relative"
							[ngClass]="{
								success: isOrderDelivered,
								fail: orderCancelledBeforeDelivery || orderCancelledBeforePickup || orderCancelledBeforeConfirmation || isOrderExceptioned
							}"
						>
							<div class="status-circle mx-auto">
								<span class="check-mark">✔</span>
								<span class="cross-mark">✖</span>
							</div>
						</div>
						<div
							class="status-text mt-2 ctooltip ctooltip-bottom text-center"
							[attr.data-tooltip-success]="('LIVE_TRACKING.STATUS_BAR_TOOLTIP.ORDER_DELIVERED_ON' | translate) + ' ' + orderDeliveredAt"
							[attr.data-tooltip-fail]="('LIVE_TRACKING.STATUS_BAR_TOOLTIP.ORDER_CANCELLED_ON' | translate ) + ' ' + orderCancelledOn"
						>
						{{ 'GENERIC.PLATFORM.DELIVERED' | translate }}<br>
							<span
								class="link"
								*ngIf="order?.attachments?.length && order?.status === 'ORDER_DELIVERED' && liveTrackingConfig?.componentVisibility?.proofOfDelivery?.active"
								(click)="openModal(content)"
							>{{ 'GENERIC.PLATFORM.DETAILS_TEXT' | translate }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- order status info - end -->

<!-- map, feedback, store & driver info - start -->
<div
	class="container-fluid max-950px mb-14px"
	*ngIf="order && liveTrackingConfig"
>
	<div class="row">
		<div class="col">
			<div class="border-1px border-radius-5px overflow-hidden">
				<!-- map, feedback cnt - start -->
				<div class="d-flex border-bottom-1px flex-column flex-md-row-reverse">
					<div
						class="flex-md-50p"
						*ngIf="isOrderDelivered && liveTrackingConfig.componentVisibility?.feedback?.active"
					>
						<ds-feedback-box
							[orderId]="order._id"
							[feedback]="order.feedback"
						></ds-feedback-box>
					</div>

					<div
						class="flex-md-50p map-cnt border-top-md-0 border-top-1px"
						[ngClass]="{ 'border-right-md-1px': isOrderDelivered }"
					>
						<ds-google-map [order]="order"></ds-google-map>
					</div>
				</div>
				<!-- map, feedback cnt - end -->

				<div
					class=""
					*ngIf="liveTrackingConfig?.componentVisibility"
				>
					<ds-store-driver-info
						[liveTrackingConfig]="liveTrackingConfig"
						[order]="order"
					></ds-store-driver-info>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- map, feedback, store & driver info - end -->

<!-- customer info - start -->
<div
	class="container-fluid max-950px mb-14px customer-details-cnt"
	*ngIf="order"
>
	<div class="row">
		<div class="col">
			<div class="border-1px border-radius-5px d-flex flex-column flex-md-row">
				<div class="d-flex flex-1-1-auto flex-column flex-50p p-3 customer-details-cnt">
					<div class="section-title py-2 f-10px">{{ 'LIVE_TRACKING.CUSTOMER_INFO.DELIVERY_TO' | translate | uppercase }}:</div>

					<div class="d-flex overflow-hidden">
						<div
							class="customer-initials d-inline-flex justify-content-around align-items-center"
							[ngClass]="{ 'two-letters': customerInitials.match(' ') }"
						>
							{{ customerInitials }}
						</div>

						<div class="overflow-hidden ms-2">
							<div class="primary-data">{{ order.deliveryContact.name }}</div>
							<div class="text-ellipsis f-12px overflow-hidden">{{ customerAddress }}</div>
						</div>
					</div>
				</div>

				<div
					class="d-flex p-3 flex-1-1-auto flex-column flex-50p"
					*ngIf="order.deliveryInstructions"
				>
					<div class="section-title py-2 f-10px">{{ 'GENERIC.PLATFORM.DELIVERY' | translate | uppercase }} {{ 'GENERIC.PLATFORM.INSTRUCTIONS' | translate | uppercase }}:</div>
					<div class="delivery-instructions fixed-height">
						{{ readMoreOrLess ? deliveryInstructions : desktopDeliveryInstructions }}

						<button
							(click)="readMoreOrLess = !readMoreOrLess"
							type="button"
							class="read-more-btn d-inline btn btn-link f-14px p-0 width-fit-content"
							*ngIf="deliveryInstructions.length > 134"
						>
							{{ (readMoreOrLess ? 'GENERIC.COMMON_PAGES.READ_LESS' : 'GENERIC.COMMON_PAGES.READ_MORE') | translate }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- customer info - end -->

<!-- ads - start -->
<div
	class="container-fluid max-950px mb-14px top-ads-cnt"
	*ngIf="liveTrackingBranding"
>
	<div class="row">
		<!-- When primary and secondary 1 is available start -->
		<ng-container *ngIf="(primaryAds && primaryAds.url && secondaryAdsOne && secondaryAdsOne.url)">
			<div class="col-12 col-md-6 padding-top-7px mt-14px mt-md-0 padding-left-md-7px d-none d-sm-block">
				<ds-single-ads-banner
					[adconfig]="secondaryAdsOne"
					[captureEvent]="'click-live-tracking-secondary-ads-one'"
				></ds-single-ads-banner>
			</div>
			<div class="col-12 col-md-6 padding-right-md-7px">
				<ds-single-ads-banner
					[adconfig]="primaryAds"
					[captureEvent]="'click-live-tracking-primary-ads'"
				></ds-single-ads-banner>
			</div>

		</ng-container>
		<!-- When primary and secondary 2 is avaiable secondary 1 is not available end -->
		<ng-container
			*ngIf="(primaryAds && primaryAds.url && secondaryAdsTwo &&  secondaryAdsTwo.url && (!secondaryAdsOne || !secondaryAdsOne.url))"
		>
			<div class="col-12 col-md-6 padding-top-7px mt-14px mt-md-0 padding-left-md-7px d-none d-sm-block">
				<ds-single-ads-banner
					[adconfig]="secondaryAdsTwo"
					[captureEvent]="'click-live-tracking-secondary-ads-two'"
				></ds-single-ads-banner>
			</div>
			<div class="col-12 col-md-6 padding-right-md-7px">
				<ds-single-ads-banner
					[adconfig]="primaryAds"
					[captureEvent]="'click-live-tracking-primary-ads'"
				></ds-single-ads-banner>
			</div>
		</ng-container>
		<!-- When primary is available and both secondary are not available-->
		<ng-container
			*ngIf="(primaryAds && primaryAds.url && (!secondaryAdsTwo ||  !secondaryAdsTwo.url) && (!secondaryAdsOne || !secondaryAdsOne.url))"
		>
			<div class="col-md-3"></div>
			<div class="col-12 col-md-6 padding-right-md-7px">
				<ds-single-ads-banner
					[adconfig]="primaryAds"
					[captureEvent]="'click-live-tracking-primary-ads'"
				></ds-single-ads-banner>
			</div>
			<div class="col-md-3"></div>

		</ng-container>
	</div>
</div>
<!-- ads - end -->

<!-- order info - start -->
<div
	class="container-fluid max-950px mb-14px order-details-cnt"
	*ngIf="orderValueVisibility || orderItemsVisibility"
>
	<div class="row">
		<div class="col">
			<div class="border-1px border-radius-5px">
				<div class="border-bottom-1px p-3">
					<h2 class="f-16px mb-0 fw-normal">{{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} {{ 'GENERIC.PLATFORM.DETAILS_TEXT' | translate }}</h2>
				</div>

				<div
					class="order-value-cnt p-3"
					*ngIf="orderValueVisibility"
				>
					<span class="order-value-label d-inline-block">{{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} {{ 'GENERIC.PLATFORM.VALUE_TEXT' | translate }}: </span>
					<span class="order-value d-inline-block ms-1"> ${{ order?.orderValue }}</span>
				</div>

				<div
					class="order-items-cnt p-3 pt-0"
					*ngIf="order && orderItemsVisibility && packageItemList.length"
				>
					<span class="fw-light f-12px">{{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} {{ 'GENERIC.PLATFORM.ITEMS' | translate }}:</span>

					<div class="border-1px border-radius-5px d-flex flex-wrap p-3 package-contents-body">
						<ng-container *ngFor="let item of packageItemList; let index = index">
							<!-- horizontal border b/w rows -->
							<hr
								class="col-12 px-0 my-0"
								*ngIf="index !== 0 && index % 2 === 0"
							/>

							<div
								class="col-12 col-md-6 px-0 position-relative"
								[ngClass]="{ 'pe-md-3': index % 2 === 0 }"
							>
								<div
									*ngIf="item.description && !orderHiddenFields['description']"
									class="item-description-info position-absolute width-fit-content ctooltip ctooltip-top"
									[attr.data-tooltip]="item.description"
								>
									<span>&#9432;</span>
								</div>
								<div class="d-flex py-3 pe-3 pe-lg-5">
									<div style="width: 100px; height: 100px">
										<img
											style="max-width: 100%; max-height: 100%; text-align: center"
											class="flex-grow-0 flex-shrink-0"
											src="{{ item.image || '/assets/icons/package-item-placeholder.svg' }}"
											[alt]="item.title"
										/>
									</div>
									<div class="flex-fill ps-3 min-width-0">
										<div
											class="item-name f-18px width-fit-content"
											*ngIf="item.title"
										>
											{{ item.title }}
										</div>
										<div
											class="details f-14px"
											*ngIf="item.sku && !orderHiddenFields['sku']"
										>
											<span class="font-weight-light">{{ 'GENERIC.PLATFORM.SKU_TEXT' | translate }}:</span> {{ item.sku }}
										</div>

										<div class="d-flex justify-content-between flex-wrap">
											<div
												class="details f-14px width-fit-content"
												*ngIf="item.quantity && !orderHiddenFields['quantity']"
											>
												<span class="font-weight-light">{{ 'GENERIC.PLATFORM.QUANTITY_TEXT' | translate }}:</span> {{ item.quantity }}
											</div>
											<div
												class="details f-14px width-fit-content"
												*ngIf="item.sale_price !== undefined && item.price !== undefined  && !orderHiddenFields['price']"
											>
												<span class="font-weight-light">{{ 'GENERIC.PLATFORM.PRICE_TEXT' | translate }}:</span>
												<span>&nbsp;${{ item.sale_price }}</span>&nbsp;
												<span
													style="text-decoration: line-through"
													class="f-12px"
												>${{ item.price }}</span>
											</div>
											<div
												class="details f-14px width-fit-content"
												*ngIf="
													(item.sale_price === undefined && item.price !== undefined) ||
													(item.sale_price !== undefined && item.price === undefined) &&
													!orderHiddenFields['price']
												"
											>
												<span class="font-weight-light">{{ 'GENERIC.PLATFORM.PRICE_TEXT' | translate }}:</span>${{ item.price || item.sale_price }}
											</div>
										</div>
										<div class="d-flex justify-content-between flex-wrap">
											<div
												class="details f-14px width-fit-content"
												*ngIf="item.weight && !orderHiddenFields['weight']"
											>
												<span class="font-weight-light">{{ 'GENERIC.PLATFORM.WEIGHT_TEXT' | translate }}:</span> {{ item.weight }} lbs
											</div>
											<div
												class="details f-14px width-fit-content"
												*ngIf="item.size && !orderHiddenFields['size']"
											>
												<span class="font-weight-light">{{ 'GENERIC.PLATFORM.SIZE_TEXT' | translate }}:</span> {{ item.size.length }} in x
												{{ item.size.width }} in x {{ item.size.height }} in
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- order info - end -->

<!-- order attributes - start -->
<div
	class="container-fluid max-950px mb-14px order-attribute-cnt"
	*ngIf="orderAttributeList.length"
>
	<div class="row">
		<div class="col">
			<div class="border-1px border-radius-5px">
				<div class="border-bottom-1px p-3">
					<h2 class="f-16px mb-0 fw-normal">{{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} {{ 'GENERIC.PLATFORM.ATTRIBUTES_TEXT' | translate }}</h2>
				</div>

				<div class="d-flex flex-wrap">
					<div
						*ngFor="let attribute of orderAttributeList; let index = index"
						class="attribute py-14px mx-14px d-flex justify-content-between flex-wrap"
						[ngClass]="{ 'border-top-1px': index !== 0 && index !== 1 }"
					>
						<span class="fw-light">{{ attribute.key }}</span>
						<span>{{ attribute.value }}</span>
					</div>
					<!-- repeat above element when no. of attributes is odd -->

				</div>
			</div>
		</div>
	</div>
</div>
<!-- order attributes - end -->

<!-- order not found - start  -->

<div
	class="container-fluid max-950px mb-14px"
	*ngIf="!order"
>
	<div class="row">
		<div class="col-12 col-md-6 padding-right-md-7px">
			<ds-order-unavailable></ds-order-unavailable>
		</div>
		<div class="col-12 col-md-6 padding-top-7px mt-14px mt-md-0 padding-left-md-7px">
			<ds-single-ads-banner
				[adconfig]="primaryAds"
				*ngIf="primaryAds" [captureEvent]="'click-live-tracking-primary-ads'"
			></ds-single-ads-banner>
		</div>
	</div>
</div>
<!-- order not found - end  -->

<!-- ads - start -->
<!-- When both secondary are available -->
<div
	class="container-fluid max-950px mb-14px top-ads-cnt"
	*ngIf="secondaryAdsOne && secondaryAdsOne.url && secondaryAdsTwo && secondaryAdsTwo.url"
>
	<div class="row">
		<div class="col-12 col-md-6 padding-right-md-7px">
			<ds-single-ads-banner
				[adconfig]="secondaryAdsOne"
				*ngIf="secondaryAdsOne" [captureEvent]="'click-live-tracking-secondary-ads-one'"
			></ds-single-ads-banner>
		</div>
		<div class="col-12 col-md-6 padding-top-7px mt-14px mt-md-0 padding-left-md-7px">
			<ds-single-ads-banner
				[adconfig]="secondaryAdsTwo"
				*ngIf="secondaryAdsTwo" [captureEvent]="'click-live-tracking-secondary-ads-two'"
			></ds-single-ads-banner>
		</div>
	</div>
</div>
<!-- When secondary 1 is available but not secondary 2 and not primary -->
<div
	class="container-fluid max-950px mb-14px top-ads-cnt"
	*ngIf="secondaryAdsOne && secondaryAdsOne.url && (!secondaryAdsTwo || !secondaryAdsTwo.url) && (!primaryAds || !primaryAds.url)"
>
	<div class="row">
		<div class="col-md-3"></div>
		<div class="col-12 col-md-6 padding-right-md-7px">
			<ds-single-ads-banner
				[adconfig]="secondaryAdsOne"
				*ngIf="secondaryAdsOne" [captureEvent]="'click-live-tracking-secondary-ads-one'"
			></ds-single-ads-banner>
		</div>
		<div class="col-md-3"></div>
	</div>
</div>
<!-- When secondary 2 is available but not secondary 1 and not primary -->
<div
	class="container-fluid max-950px mb-14px top-ads-cnt"
	*ngIf="(!secondaryAdsOne || !secondaryAdsOne.url) && secondaryAdsTwo && secondaryAdsTwo.url && (!primaryAds || !primaryAds.url)"
>
	<div class="row">
		<div class="col-md-3"></div>
		<div class="col-12 col-md-6 padding-right-md-7px">
			<ds-single-ads-banner
				[adconfig]="secondaryAdsTwo"
				*ngIf="secondaryAdsTwo" [captureEvent]="'click-live-tracking-secondary-ads-two'"
			></ds-single-ads-banner>
		</div>
		<div class="col-md-3"></div>
	</div>
</div>
<!-- When primary and secondary 1 is available but not secondary 2 -->
<div
	class="container-fluid max-950px mb-14px top-ads-cnt"
	*ngIf="secondaryAdsOne && secondaryAdsOne.url && (!secondaryAdsTwo || !secondaryAdsTwo.url) && primaryAds && primaryAds.url"
>
	<div class="row">

		<div class="col-12 col-md-6 padding-right-md-7px d-sm-block d-lg-none">
			<ds-single-ads-banner
				[adconfig]="secondaryAdsOne"
				*ngIf="secondaryAdsOne" [captureEvent]="'click-live-tracking-secondary-ads-one'"
			></ds-single-ads-banner>
		</div>
	</div>
</div>
<!-- When primary and secondary 2 is available but not secondary 1 -->
<div
	class="container-fluid max-950px mb-14px top-ads-cnt"
	*ngIf="(!secondaryAdsOne || !secondaryAdsOne.url) && secondaryAdsTwo && secondaryAdsTwo.url && primaryAds && primaryAds.url"
>
	<div class="row">
		<div class="col-12 col-md-6 padding-right-md-7px d-sm-block d-lg-none">
			<ds-single-ads-banner
				[adconfig]="secondaryAdsTwo"
				*ngIf="secondaryAdsTwo" [captureEvent]="'click-live-tracking-secondary-ads-two'"
			></ds-single-ads-banner>
		</div>
	</div>
</div>
<!-- ads - end -->

<!-- footer - start -->
<div class="container-fluid max-950px mb-14px">
	<div class="row">
		<div class="col">
			<ds-site-footer></ds-site-footer>
		</div>
	</div>
</div>
<!-- footer - end -->

<!-- Need Help start -->
<a
	class="need-help-section f-16px"
	[href]="liveTrackingBranding?.components?.needHelp?.helpUrl"
	*ngIf="liveTrackingBranding?.components?.needHelp?.helpUrl"
	target="_blank" [captureEvent]="'click-live-tracking-help-menu'"
>
	<span class="question-mark flex-shrink-0">?</span>&nbsp;<span
		class="needhelp-txt d-none d-sm-block" >{{liveTrackingBranding?.components?.needHelp?.displayText}}</span>
</a>
<!-- Need Help end -->
<!-- Proof of Delivery Modal -->
<ng-template
	#content
	let-d="dismiss"
>
	<div class="modal-header">
		<button
			type="button"
			class="btn-close"
			(click)="d()"
			aria-label="Close"
		></button>
	</div>
	<div class="modal-body">
		<ds-images-modal
			[images]="images"
			[desktopMode]="desktopMode"
		></ds-images-modal>
	</div>
</ng-template>
<!-- Site Cookie Consent -->
<ds-cookie-consent
	*ngIf="order"
	[order]="order"
> </ds-cookie-consent>
