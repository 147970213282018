

export const ORDER_DELIVERED_CATEGORY = ["DELIVERED", "ORDER_DELIVERED"];
export const OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY"

export const ORDER_CANCELLED_CATEGORY = ['CANCELLED', "ORDER_CANCELLED", 'ORDER_UNDELIVERABLE', 'ORDER_RETURNED', 'PICKUP_EXCEPTION'];
export const ORDER_EXCEPTION = 'ORDER_EXCEPTION';
export const ORDER_DISPATCHED = 'ORDER_DISPATCHED';
export const ORDER_UNASSIGNED = 'ORDER_UNASSIGNED';
export const ORDER_DELAYED = 'ORDER_DELAYED';
export const ORDER_FAILED_CATEGORY = ['REQUEST_FAILED', 'ESTIMATES_FAILED', 'ORDER_FAILED'];

export const SystemStatus = [
	'REQUEST_RECEIVED',
	'ESTIMATES_RECEIVED',
	'PENDING_DISPATCH',
	'PENDING_RELEASE',
	'PENDING_BATCHING',
	'PENDING_RETRY',
	'PENDING_CANCEL',
	'ESTIMATES_FAILED',
	'PENDING_AUTORETRY'
];

export const ORDER_DISPATCHED_CATEGORY = ['ORDER_DISPATCHED', 'STAGED', 'DISPATCHED', 'ORDER_ASSIGNED', 'PICKUP_STARTED', 'PICKUP_AT_LOCATION', ORDER_UNASSIGNED, "PICKUP_NEARBY"];
export const OUT_FOR_DELIVERY_CATEGORY = ['PICKUP_COMPLETED', 'ORDER_REDELIVERY', OUT_FOR_DELIVERY, 'IN_TRANSIT', 'PARTIAL_IN_TRANSIT', 'DELIVERY_AT_LOCATION', 'PARTIAL_DELIVERED', ORDER_DELAYED];
export const ORDER_AT_LOCATION = 'ORDER_AT_LOCATION';
export const STAGED_CATEGORY = ['STAGED', 'ORDER_ASSIGNED', 'PICKUP_STARTED', 'PICKUP_AT_LOCATION'];


export const ORDER_STATUS_AND_TITLE_MAPPING = {
	ORDER_PENDING_DISPATCHED: 'Delivery not scheduled',
	ORDER_DISPATCHED: 'Scheduled Delivery By ',
	ORDER_DISPATCHED_ETA: 'Estimated Delivery on ',
	ORDER_OUT_FOR_DELIVERY: 'Scheduled Arrival By ',
	ORDER_OUT_FOR_DELIVERY_ETA: 'Arriving in ',
	ORDER_AT_LOCATION: 'Dropped At Location on ',
	ORDER_DELIVERED: 'Delivered on ',
	ORDER_CANCELLED: 'Cancelled on ',
	ORDER_FAILED: 'Failed on ',
	ORDER_EXCEPTION: 'Order Failed on ',
}