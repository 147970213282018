import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
