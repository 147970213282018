import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  private readonly helper = new JwtHelperService();

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers: any = {
      'x-compression': 'true',
      'Cache-Control': 'no-store'
    };

    const params: any = new URLSearchParams(window.location.search);

    if (params.get('token')) {
      headers['x-token'] = params.get('token');
    }

    if (request.headers.has("x-token")) {
			headers['x-token'] = request.headers.get('x-token');
    }

    if (environment.env !== "local" && !window.location.host.includes('localhost') && !window.location.host.endsWith('deliverysolutions.co')) {
      // custom domain
      headers['custom-domain'] = window.location.host;
    }

  

    return next.handle(request.clone({ setHeaders: headers }));
  }
}
