export function addMinutesToDate(date: Date, minutes: number): Date {
	return new Date(date.getTime() + minutes * 60000);
}

export function generateEstimatedDeliveryTime(date: Date): string {
	const dateFormatter = new Intl.DateTimeFormat('en-US', {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	});
	const timeFormatter = new Intl.DateTimeFormat('en-US', {
		hour: 'numeric',
		minute: 'numeric',
	});

	const formattedDate = dateFormatter.format(new Date(date));
	const formattedTime = timeFormatter.format(new Date(date));

	return `${formattedDate} ${formattedTime}`;
}
export function getDate(timestamp: number, timeZone: string): string {
	const date = new Intl.DateTimeFormat('en-US', {
		timeZone,
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	});

	return date.format(new Date(timestamp));
}
export function getTime(timestamp: number, timeZone: string): string {
	const time = new Intl.DateTimeFormat('en-US', {
		timeZone,
		hour: 'numeric',
		minute: 'numeric',
	});

	return time.format(new Date(timestamp));
}

export function generateOrderDeliveryTime(timestamp: number, timeZone: string) {
	const dateFormatter = new Intl.DateTimeFormat('en-US', {
		timeZone,
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	});
	const timeFormatter = new Intl.DateTimeFormat('en-US', {
		timeZone,
		hour: 'numeric',
		minute: 'numeric',
	});

	const formattedDate = dateFormatter.format(new Date(timestamp));
	const formattedTime = timeFormatter.format(new Date(timestamp));

	return `${formattedDate} ${formattedTime}`;
}


export function generateDatetimeToDisplayOnOrderProgressbar(timestamp: number, timeZone: string) {

	const dateOptions = {
		timeZone,
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	};

	const timeOptions = {
		timeZone,
		hour: 'numeric',
		minute: 'numeric',
	};

	const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions as any);
	const timeFormatter = new Intl.DateTimeFormat('en-US', timeOptions as any);

	const formattedDate = dateFormatter.format(new Date(timestamp));
	const formattedTime = timeFormatter.format(new Date(timestamp));

	return `${formattedDate} ${formattedTime}`;
}

export function generateHumanReadableDatetimeFormat(timestamp1: number, timestamp2: number) {
	let delta = Math.abs(timestamp2 - timestamp1);
	delta = delta / 1000; // convert into seconds

	delta = Math.trunc(delta / 60); // convert into minutes

	if (delta < 60) {
		return `${delta} minutes`;
	}

	const hours = Math.trunc(delta / 60);
	const minutes = delta % 60;

	const hoursPart = hours > 1 ? `${hours} hours` : `${hours} hour`;

	if (minutes === 0) {
		return hoursPart
	}

	return hoursPart + ', ' + minutes + ' minutes';
}
