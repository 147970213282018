<div class="feedback-container d-flex flex-column h-100">
  <div class="title-container p-3">
    <h2 class="title mb-0">
			{{ 'GENERIC.COMMON_PAGES.FEEDBACK_TEXT' | translate }}
    </h2>
  </div>
  <form class="flex-grow-1 d-flex flex-column" *ngIf="!isFeedbackShared" (submit)="onSubmit()">
    <div>
      <div class="align-items-center d-flex flex-column flex-md-row justify-content-between p-3">
        <div class="update-text f-14px flex-shrink-1">
					{{ 'GENERIC.COMMON_PAGES.FEEDBACK_RATE_EXPERIENCE_DELIVERY' | translate }}
        </div>
        <div class="d-inline-flex flex-shrink-0 mt-3 mt-md-1 width-fit-content">
          <label class="ms-lg-4">
            <input type="radio" name="feedback" value="5" required class="feedback-radio"
              [(ngModel)]="formObject.rating">
            <img width="36" height="36" src="../../../assets/icons/smiley-1.svg" class="unselected">
            <img
              width="36"
              height="36"
              src="../../../assets/icons/smiley-1-s.svg"
              class="selected"
              [captureEvent]="'click-feedback-rating'"
            >
          </label>
          <label class="ms-4 custom-ms-3-280px">
            <input type="radio" name="feedback" value="4" required class="feedback-radio"
              [(ngModel)]="formObject.rating">
            <img width="36" height="36" src="../../../assets/icons/smiley-2.svg" class="unselected">
            <img
              width="36"
              height="36"
              src="../../../assets/icons/smiley-2-s.svg"
              class="selected"
              [captureEvent]="'click-feedback-rating'"
            >
          </label>
          <label class="ms-4 custom-ms-3-280px">
            <input type="radio" name="feedback" value="3" required class="feedback-radio"
              [(ngModel)]="formObject.rating">
            <img width="36" height="36" src="../../../assets/icons/smiley-3.svg" class="unselected">
            <img
              width="36"
              height="36"
              src="../../../assets/icons/smiley-3-s.svg"
              class="selected"
              [captureEvent]="'click-feedback-rating'"
            >
          </label>
          <label class="ms-4 custom-ms-3-280px">
            <input type="radio" name="feedback" value="2" required class="feedback-radio"
              [(ngModel)]="formObject.rating">
            <img width="36" height="36" src="../../../assets/icons/smiley-4.svg" class="unselected">
            <img
              width="36"
              height="36"
              src="../../../assets/icons/smiley-4-s.svg"
              class="selected"
              [captureEvent]="'click-feedback-rating'"
            >
          </label>
          <label class="ms-4 custom-ms-3-280px">
            <input type="radio" name="feedback" value="1" required class="feedback-radio"
              [(ngModel)]="formObject.rating">
            <img width="36" height="36" src="../../../assets/icons/smiley-5.svg" class="unselected">
            <img
              width="36"
              height="36"
              src="../../../assets/icons/smiley-5-s.svg"
              class="selected"
              [captureEvent]="'click-feedback-rating'"
            >
          </label>
        </div>
      </div>
    </div>
    <div class="mx-3 flex-grow-1">
			<textarea type="text" class="form-control h-100" placeholder="{{ 'GENERIC.COMMON_PAGES.COMMENT' | translate }}" name="comment"
        [(ngModel)]="formObject.comment">
        </textarea>
    </div>
    <div class="flex-grow-0 d-flex flex-column justify-content-around">
      <button
        type="submit"
        class="btn btn-primary primary-btn my-3"
        [captureEvent]="'click-send-feedback'"
      >
				{{ 'GENERIC.COMMON_PAGES.SEND_FEEDBACK' | translate | uppercase }}
      </button>
    </div>
  </form>
  <div *ngIf="isFeedbackShared" class="feedback-shared d-flex flex-column justify-content-center">
    <div class="feedback-success mt-3"><img src="/assets/icons/feedback-shared.svg" width="20" height="20"
        alt="Avatar">
    </div>
		<h3 class="my-3">{{ 'GENERIC.COMMON_PAGES.THANK_YOU_TEXT' | translate }}!</h3>
		<p class="f-14px mb-3 px-3">{{ 'GENERIC.COMMON_PAGES.FEEDBACK_FOOTER_TEXT_DELIVERY' | translate }}</p>
    <p class="cursor-pointer f-12px"
			(click)="isFeedbackShared = false" [captureEvent]="'click-edit-feedback'"><u>{{ 'GENERIC.COMMON_PAGES.EDIT_FEEDBACK' | translate }}</u></p>
  </div>
</div>
