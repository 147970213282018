import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { LiveTrackingConfigInterface } from "../ts-interfaces/live-tracking-config.interface";
import { BaseService } from "./base.service";
import * as CryptoJS from 'crypto-js';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseService {
  public googleMapsApiKey: string | null = null;
  constructor(private httpClient: HttpClient) {
    super();
  }

  // fetch ltf config and order data
  get(orderId: string): Observable<any | undefined | string> {
    const getOrderAndConfigUrl = this.serverRootPathV2 + "live-tracking/order/" + orderId;

    return this.httpClient.get(getOrderAndConfigUrl)
      .pipe(
        tap((res: any) => {
          if (environment.env === "local") {
            this.googleMapsApiKey = environment.googleMapsAPIKey;
          } else if (res.googleMapsApiKey) {
            const decryptedMapsApiKey = this.decryptMapsApiKey(res.googleMapsApiKey);
            this.googleMapsApiKey = decryptedMapsApiKey;
          }
        }),
        catchError(e => {
          // TODO handle properly
          console.log('error in fetching order', e);

          return of(undefined)
        }));
  }

  getFakeOrder(): any {
    return {
      "_id": "6217791f96e2757548948e1e",
      "estimatedDeliveryTime": 1645709000253,
      "estimatedDeliveryTimeByZone": "February 24th 2022, 8:23 am EST",
      "estimatedPickupTime": 1645708940253,
      "estimatedPickupTimeByZone": "February 24th 2022, 8:22 am EST",
      "trackingUrl": "https://q.dl-s.co/kjvwiSkWyDm",
      "provider": "AxleHire",
      "type": "delivery",
      "storeExternalId": "Axle Hire store",
      "orderExternalId": "SH 112",
      "orderValue": 49,
      "lastLocation": {
        "latitude": 41.1772344937365,
        "longitude": -104.970071017742
      },
      "driver": {
        "name": "Noel Potts",
        "phone": "+1 223-456-7890"
      },
      "dropoffTime": {
        "endsAt": 1645709000253,
        "startsAt": null
      },
      "pickupTime": {
        "startsAt": 1645708940253,
        "endsAt": null
      },
      "deliveryAddress": {
        "street": "11111 Research Boulevard",
        "street2": "",
        "city": "Austin",
        "state": "TX",
        "country": "US",
        "zipcode": "78759",
        "latitude": 30.4054411,
        "longitude": -97.7437953,
        "apartmentNumber": "",
        "reportLatitude": 30.4054411,
        "reportLongitude": -97.7437953
      },
      "deliveryContact": {
        "name": "Jane Lisa",
        "phone": "+1 234-567-8900",
        "customerId": "",
        "email": "",
        "notifySms": true,
        "notifyEmail": true
      },
      "itemList": [
        {
            "sku": "sku 1",
            "quantity": 1,
            "size": {
                "length": 4,
                "width": 2,
                "height": 2
            },
            "weight": 4,
            "price": 2,
            "sale_price": 9,
            "image": "https://qa.files.deliverysolutions.co/items/[deliverysolutions123456]/zebra/sku_1_1637668517914.png",
          "title": "Gloves",
          "description": "Will keep you warm in winter"
        },
        {
            "sku": "Sku 2",
            "quantity": 1,
            "size": {
                "length": 1,
                "width": 2,
                "height": 2
            },
            "weight": 13,
            "price": 3,
            "sale_price": 24,
            "image": "https://qa.files.deliverysolutions.co/items/[deliverysolutions123456]/zebra/sku_2_1637668552003.png",
          "title": "Glasses",
          "description": "Looks sexy on you!"
        },
        {
            "sku": "sku 3",
            "quantity": 1,
            "size": {
                "length": 5,
                "width": 4,
                "height": 3
            },
            "weight": 6,
            "price": 7,
            "sale_price": 8,
            "image": "https://qa.files.deliverysolutions.co/items/[deliverysolutions123456]/zebra/sku_3_1637668585673.png",
          "title": "Black Coffee",
          "description": "Will make you feel younger..."
        },
        {
            "sku": "sku4",
            "quantity": 1,
            "size": {
                "length": 4,
                "width": 3,
                "height": 2
            },
            "weight": 7,
            "price": 9,
            "sale_price": 8,
            "image": "https://qa.files.deliverysolutions.co/items/[deliverysolutions123456]/zebra/sku4_1637668619478.png",
          "title": "Apache Watch",
          "description": "Watch that shows your standard."
        }
      ],
      "tips": null,
      "pickupInstructions": "",
      "brandExternalId": "zebra",
      "userPickupTime": 1645708940253,
      "timeZone": "America/New_York",
      "pickUpContact": {
        "name": "Axle Hire store",
        "phone": "+1 234-567-8900"
      },
      "pickUpAddress": {
        "street": "225 Baker Street Northwest",
        "street2": "",
        "secondary": "",
        "city": "Atlanta",
        "zipcode": "30313",
        "country": "US",
        "state": "GA",
        "latitude": 33.7638778,
        "longitude": -84.3957609
      },
			"status": "OUT_FOR_DELIVERY",
      "createdAt": "2022-02-24T12:25:03.573Z",
      "hostedTrackingUrl": "https://q.dl-s.co/6Zh0GUbr-hU",
      "store": {
        "_id": "602266361dee3c65face54c0",
        "tenantId": "zebra",
        "active": true,
        "createdAt": "2021-02-09T10:38:46.445Z",
        "name": "Axle Hire store",
        "description": "",
        "storeExternalId": "Axle Hire store",
        "contact": {
          "name": "Axle Hire store",
          "phone": "+1 234-567-8900"
        },
        "pickupInstructions": "",
        "address": {
          "street": "225 Baker Street Northwest",
          "street2": "",
          "secondary": "",
          "city": "Atlanta",
          "zipcode": "30313",
          "country": "US",
          "state": "GA",
          "latitude": 33.7638778,
          "longitude": -84.3957609
        },
        "departments": [
          {
            "name": "",
            "description": "",
            "pickupInstructions": ""
          }
        ],
        "dsp": "",
        "brandExternalId": "zebra",
        "currencyCode": "USD"
      },
      "customerWebflowUrl": null,
      "isRx": true,
      "isTobacco": true,
      "isSpirit": true,
      "parentOrderId": null,
      "providerTrackingUrl": "https://tracking.staging.axlehire.com/kk3yq3avz5qkn8h3",
      "trackingNumber": "kk3yq3avz5qkn8h3",
      "statusHistory": [
        {
          "status": "REQUEST_RECEIVED",
          "updatedAt": "February 24th 2022, 6:25:03 am CST",
          "updatedAtEpoch": 1645705503806,
          "note": null
        },
        {
          "status": "ESTIMATES_RECEIVED",
          "updatedAt": "February 24th 2022, 6:25:03 am CST",
          "updatedAtEpoch": 1645705503971,
          "note": null
        },
        {
          "status": "ORDER_DISPATCHED",
          "updatedAt": "February 24th 2022, 6:25:05 am CST",
          "updatedAtEpoch": 1645705505000,
          "note": null
        }
      ],
      "brandName": "zebra",
      "brandLogo": "https://qa.files.deliverysolutions.co/zebra/tenantassets/brand_2/image/logo/1653903428821.jpg"
    }
  }

  getOrderStatus(orderId: string): Observable<any | undefined | string> {
    const getOrderStatusHistoryUrl = this.serverRootPathV2 + 'order/track/live-tracking/status/' + orderId;

    return this.httpClient.get(getOrderStatusHistoryUrl)
      .pipe(catchError(e => {
        // TODO handle properly
        console.log('error in fetching order', e);

        return of(undefined)
      }));
  }

  setHiddenFields(liveTrackingConfig: LiveTrackingConfigInterface) {
    let orderHiddenFields: { [key: string]: boolean } = {};
    const orderItemFields = liveTrackingConfig?.componentVisibility?.orderDetails?.orderItems?.hideFields;

    orderItemFields?.map((field) => {
      orderHiddenFields[field] = true;
    })

    return orderHiddenFields;
  }

  getGoogleMapsApiKey() {
    return this.googleMapsApiKey || null;
  }

  decryptMapsApiKey(googleMapsApiKey: string): string {
    return CryptoJS.AES.decrypt(googleMapsApiKey, environment.googleMapsApiKeyDecryptionPassphrase).toString(CryptoJS.enc.Utf8);
  }
}
