import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-order-unavailable',
  templateUrl: './order-unavailable.component.html',
  styleUrls: ['./order-unavailable.component.scss']
})
export class OrderUnavailableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
